import React from "react";

const IpStatusList = (props) => {
  var muted = false;
  const { alerts } = props;
  const { id, name, status } = props.connection;

  const findAlert = alerts.find(
    (site) => site.Asset_id === `IP${id}` && site.Active === "true"
  );

  if (
    findAlert !== undefined &&
    findAlert.Alert === "false" &&
    findAlert.Active === "true"
  ) {
    muted = true;
  }

  if (muted === false) {
    if (status === "Pass") {
      return null;
    } else if (muted === false) {
      return (
        <tr className="bg-danger text-white">
          <td className="dashboard-item">{name}</td>
          <td className="text-align-center dashboard-item">CONNECTION DOWN</td>
        </tr>
      );
    } else if (status === "Undetermined") {
      return (
        <tr className="bg-warning text-white">
          <td>{name}</td>
          <td className="text-align-center">CONNECTION ISSUES</td>
        </tr>
      );
    } else if (status === "Warning") {
      return (
        <tr className="bg-warning text-white">
          <td>{name}</td>
          <td className="text-align-center">CONNECTION ISSUES</td>
        </tr>
      );
    }
  }
  if (muted === true) {
    return null;
  }
};

export default IpStatusList;
