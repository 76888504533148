import React from "react";

const MosScoreDetail = (props) => {
  const { loadMosScorePage, mos_log } = props;
  const {
    BTC,
    CMOS,
    CallID,
    DDD,
    Destination,
    LP,
    MOS,
    MPJ,
    OOP,
    PD,
    PLB,
    PPL,
    PacketJitter,
    RR,
    SRE,
    Site,
    Source,
    TimeStamp,
    _id,
    Raw_Message,
  } = mos_log;

  function formatDate(string) {
    var options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/New_York",
    };
    return new Date(string).toLocaleDateString("en-US", options);
  }

  return (
    <div className="container">
      <div className="row">
        <h2 className="text-right">MOS DETAILS</h2>
      </div>
      <div className="row">
        <button
          onClick={loadMosScorePage}
          className="btn btn-danger float-right"
        >
          BACK
        </button>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  SITE
                </th>
                <td>{Site}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  TimeStamp
                </th>
                <td>{formatDate(TimeStamp)}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  DB ID
                </th>
                <td>{_id}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  CALL ID
                </th>
                <td>{CallID}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  SOURCE
                </th>
                <td>{Source}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  DESTINATION
                </th>
                <td>{Destination}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  DEST DID
                </th>
                <td>{DDD}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  EXPECTED PACKETS
                </th>
                <td>{SRE}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  PACKETS RECEIVED
                </th>
                <td>{RR}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-6">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  MOS SCORE
                </th>
                <td>{MOS}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  CMOS SCORE
                </th>
                <td>{CMOS}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  BELOW THRESHOLD
                </th>
                <td>{BTC}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  LOST PACKETS
                </th>
                <td>{LP}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  DEGRADATION DUE TO PACKET LOSS(%)
                </th>
                <td>{PPL}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  MAX PACKET JITTER (ms)
                </th>
                <td>{MPJ}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  DEGRADATION DUE TO JITTER (%)
                </th>
                <td>{PacketJitter}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  OUT OF ORDER PACKETS
                </th>
                <td>{OOP}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  PACKET DELAY(ms)
                </th>
                <td>{PD}</td>
              </tr>
              <tr>
                <th scope="row" className="bg-dark text-white">
                  AVG PACKET LOSS BURST LENGTH
                </th>
                <td>{PLB}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <table className="table table-bordered">
          <thead className="thead thead-dark">
            <tr>
              <th>RAW SYSLOG MESSAGE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Raw_Message}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MosScoreDetail;
