import React, { useReducer } from "react";
import AlertsContext from "./alertsContext";
import alertsReducer from "./alertsReducer";
import axios from "axios";
import { GET_ALERTS, DELETE_ALERT, EDIT_ALERT, GET_ALERT } from "../types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const AlertsState = (props) => {
  const initialState = {
    alerts: null,
    alert: null,
  };

  const [state, dispatch] = useReducer(alertsReducer, initialState);

  //   GET Alerts
  const getAlerts = async () => {
    const res = await axios.get("/api/alert");
    dispatch({
      type: GET_ALERTS,
      payload: res.data,
    });
  };

  //   GET Alert
  const getAlert = (alert) => {
    dispatch({
      type: GET_ALERT,
      payload: alert,
    });
  };

  //   DELETE Alert
  const deleteAlert = async (e) => {
    console.log(e);
    await axios.delete(`/api/alert/${e}`);
    dispatch({
      type: DELETE_ALERT,
      payload: e,
    });
  };

  //   EDIT Alert
  const editAlert = async (updatedAlert) => {
    const res = await axios.put(
      `/api/alert/${updatedAlert._id}`,
      updatedAlert,
      config
    );
    dispatch({
      type: EDIT_ALERT,
      payload: res.data,
    });
  };

  return (
    <AlertsContext.Provider
      value={{
        alerts: state.alerts,
        alert: state.alert,
        getAlerts,
        deleteAlert,
        editAlert,
        getAlert,
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};

export default AlertsState;
