import React, { Fragment, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./techNotes.css";

const EditNote = (props) => {
  const { cancelInput, updateKb, kb, doneEditing } = props;
  const [editNote, setEditNote] = useState([]);
  const [editNoteBody, setEditNoteBody] = useState();

  useEffect(() => {
    if (kb !== null) {
      setEditNote(kb.Title);
      setEditNoteBody(kb.Note);
    }
  }, [kb]);

  const on_change = (e, editor) => {
    const data = editor.getData();
    setEditNoteBody(data);
  };

  const onChange = (e) => {
    setEditNote({ ...editNote, [e.target.id]: e.target.value });
  };

  const theEditorConfig = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
    link: {
      addTargetToExternalLinks: true,
    },
    mediaEmbed: {
      removeProviders: [
        "instagram",
        "twitter",
        "googleMaps",
        "flickr",
        "facebook",
        "youtube",
        "vimeo",
        "spotify",
        "dailymotion",
      ],
    },
  };

  const EditTheNote = () => {
    const theNote = {
      _id: kb._id,
      Title: editNote.Title,
      Note: editNoteBody,
    };

    updateKb(theNote);
    doneEditing();
  };
  if (editNote !== undefined && editNoteBody !== null) {
    return (
      <Fragment>
        <h3 className="text-center font-weight-bold">EDITED TECHNICAL NOTE</h3>
        <div className="container border shadow my-5">
          <form>
            <div className="form-group mt-3">
              <label htmlFor="Title" className="font-weight-bold">
                TOPIC
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={editNote}
                id="Title"
                onChange={onChange}
              />
            </div>
            <div className="form-group newNoteBody">
              <CKEditor
                config={theEditorConfig}
                data={editNoteBody}
                editor={ClassicEditor}
                onChange={on_change}
                id="editNoteBody"
                maxLength="5000"
              />
            </div>
          </form>
          <div className="buttons mb-3">
            <div className="row">
              <div className="col-lg-2">
                <button
                  className="btn btn-danger"
                  onClick={() => cancelInput()}
                >
                  CANCEL
                </button>
              </div>
              <div className="col-lg-8"></div>
              <div className="col-lg-2 text-right">
                <button className="btn btn-success" onClick={EditTheNote}>
                  {" "}
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default EditNote;
