import React from "react";
import "../../../css/Dashboard.css";

const EcStatusList = (props) => {
  var muted = false;
  const { alerts } = props;
  const { id, Company, name, status } = props.connection;

  const findAlert = alerts.find(
    (site) => site.Asset_id === `EC${id}` && site.Active === "true"
  );

  if (
    findAlert !== undefined &&
    findAlert.Alert === "false" &&
    findAlert.Active === "true"
  ) {
    muted = true;
  }

  if (muted === false) {
    if (status === "online") {
      return null;
    } else if (muted === false) {
      return (
        <tr className="bg-danger text-white">
          <td className="dashboard-data dashboard-item">
            <div className="dashboard-item font-weight-bold">{Company}</div>
            <div className="dashboard-item">{name}</div>
          </td>
        </tr>
      );
    }
  }
  if (muted === true) {
    return null;
  }
};

export default EcStatusList;
