import React, { useReducer } from "react";
import NavContext from "./navContext";
import navReducer from "./navReducer";
import { LOAD_A_PAGE } from "../types";

const NavState = (props) => {
  const initialState = {
    step: "dashboard",
  };

  const [state, dispatch] = useReducer(navReducer, initialState);

  const loadDashboard = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "dashboard",
    });
  };

  const loadWireless = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "wireless",
    });
  };

  const loadSdWan = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "sdwan",
    });
  };

  const loadSdWanProfile = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "sdwanProfile",
    });
  };

  const loadIp = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "ip",
    });
  };
  const showUsers = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "Users",
    });
  };

  const newUser = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "NewUser",
    });
  };

  const loadUserProfile = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "userProfile",
    });
  };

  const loadEcUsage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "ecUsage",
    });
  };

  const loadAlertsPage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "alerts",
    });
  };
  const loadAlertDetail = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "alert detail",
    });
  };
  const loadMosScorePage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "mos",
    });
  };
  const loadMosScoreDetail = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "mos detail",
    });
  };

  const loadVoiceStatusPage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "voice status",
    });
  };
  const loadUnreachablePage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "unreachable",
    });
  };
  const loadSwitchoversPage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "switchovers",
    });
  };
  const loadTechNotesPage = () => {
    dispatch({
      type: LOAD_A_PAGE,
      payload: "tech notes",
    });
  };

  return (
    <NavContext.Provider
      value={{
        step: state.step,
        loadDashboard,
        loadWireless,
        loadSdWan,
        loadIp,
        showUsers,
        newUser,
        loadUserProfile,
        loadSdWanProfile,
        loadEcUsage,
        loadAlertsPage,
        loadAlertDetail,
        loadMosScorePage,
        loadMosScoreDetail,
        loadVoiceStatusPage,
        loadUnreachablePage,
        loadSwitchoversPage,
        loadTechNotesPage,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

export default NavState;
