import React, { Fragment, useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import AlertContext from "../../context/alert/alertContext";
import axios from "axios";
import PasswordValidator from "../../utils/PasswordValidator";

const NewPassword = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const token = props.match.params.token;

  const [passwordResetData, setPasswordData] = useState({
    password: "",
    password2: "",
    resetPasswordToken: token,
    step: "newPassword",
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const { password, password2, step } = passwordResetData;

  const onChange = (e) =>
    setPasswordData({ ...passwordResetData, [e.target.id]: e.target.value });

  const submit = (e) => {
    e.preventDefault();
    if (password === "" || password2 === "" || password !== password2) {
      setAlert("Please Enter Matching Passwords", "warning");
      setPasswordData({
        ...passwordResetData,
        password: "",
        password2: "",
      });
    } else {
      if (PasswordValidator(password)) {
        axios.post("/api/resetpassword", passwordResetData, config);
        setAlert(`Password changed successfully`, "success");
        setPasswordData({ step: "login" });
      } else {
        setAlert("Invalid Password - See Password rules", "danger");
        setPasswordData({
          ...passwordResetData,
          password: "",
          password2: "",
        });
      }
    }
  };

  switch (step) {
    case "newPassword":
      return (
        <div className="container">
          <Fragment>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="card m-3 col-md-6">
                <h2 className="text-center">NEW PASSWORD </h2>
                <form>
                  <div className="form-group">
                    <label htmlFor="password">ENTER PASSWORD</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={onChange}
                      id="password"
                    />
                    <label htmlFor="password2">RE-ENTER PASSWORD</label>
                    <input
                      type="password"
                      className="form-control"
                      value={password2}
                      onChange={onChange}
                      id="password2"
                    />
                  </div>
                  <span className="font-weight-bold">Password Rules: </span>
                  <p className="text-muted text-center">
                    Atleast 8 Characters Uppercase Character(s) Lowercase
                    Character(s) Number(s) Special Character
                  </p>
                  <button
                    type="submit"
                    onClick={submit}
                    className="btn btn-warning m-2"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
          </Fragment>
        </div>
      );
    case "login":
      return (
        <div>
          <Redirect to="/" />) );
        </div>
      );
    default:
      return (
        <Fragment>
          <div className="container-fluid">
            <h2 className="text-center">SERVER ERROR</h2>
          </div>
        </Fragment>
      );
  }
};

export default NewPassword;
