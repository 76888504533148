import React, { useContext, useEffect, useState } from "react";
import TechNotesContext from "../../../context/techNotes/techNotesContext";
import CreateNote from "./CreateNote";
import ListNotes from "./ListNotes";
import EditNote from "./EditNote";
import ViewNote from "./ViewNote";

const TechNotes = () => {
  const techNotesContext = useContext(TechNotesContext);
  const {
    kbs,
    kb,
    getKbs,
    getKb,
    updateKb,
    deleteKb,
    createKb,
  } = techNotesContext;
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [searchBar, setSearchBar] = useState("");
  const [displayedNotes, setDisplayedNotes] = useState(null);

  useEffect(() => {
    getKbs();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterTheNotes();

    // eslint-disable-next-line
  }, [kbs, searchBar]);

  const filterTheNotes = () => {
    if (kbs !== null) {
      const searchResults = kbs.filter((kb) => {
        const regex = new RegExp(`${searchBar}`, "gi");
        return kb.Title.match(regex) || kb.Note.match(regex);
      });

      setDisplayedNotes(searchResults);
    }
  };

  const search = (e) => {
    setSearchBar(e.target.value);
  };

  const cancelInput = () => {
    setCreating(false);
    setEditing(false);
  };

  const doneCreating = () => {
    setCreating(false);
  };
  const doneViewing = () => {
    setViewing(false);
  };
  const doneEditing = () => {
    setEditing(false);
  };

  const editMode = (id) => {
    getKb(id);
    setEditing(true);
  };

  const viewMode = (id) => {
    setViewing(true);
    getKb(id);
  };

  const deleteTheKb = (id) => {
    setDeleteNoteId(id);
  };

  const cancelDeletion = () => {
    setDeleteNoteId(null);
  };

  return (
    <div className="container-fluid my-3">
      <div className="row">
        <div className="col-lg-2">
          <div className="create_button mt-4">
            <button
              className="btn btn-success"
              onClick={() => setCreating(true)}
            >
              NEW
            </button>
          </div>
          <div className="search_bar mt-3">
            <h6 className="font-weight-bold">SEARCH</h6>
            <input type="search" onChange={search} />
          </div>
        </div>
        <div className="col-lg-9">
          {viewing ? <ViewNote kb={kb} doneViewing={doneViewing} /> : null}
          {creating ? (
            <CreateNote
              cancelInput={cancelInput}
              createKb={createKb}
              doneCreating={doneCreating}
            />
          ) : null}
          {editing ? (
            <EditNote
              cancelInput={cancelInput}
              updateKb={updateKb}
              kb={kb}
              doneEditing={doneEditing}
            />
          ) : null}
          {creating === false && editing === false && viewing === false ? (
            <ListNotes
              kbs={displayedNotes}
              editMode={editMode}
              viewMode={viewMode}
              deleteTheKb={deleteTheKb}
              getKb={getKb}
            />
          ) : null}
        </div>
        <div className="col-lg-1"></div>
      </div>
      <div
        className="modal fade"
        id="noteDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="noteDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS TECH NOTE?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteKb(deleteNoteId);
                  setDeleteNoteId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TechNotes;
