import React, { Fragment, useContext, useEffect, useState } from "react";
import EcUsageContext from "../../../context/ecUsage/ecUsageContext";
import EcUsageList from "./EcUsageList";

const EcUsage = () => {
  const ecUsageContext = useContext(EcUsageContext);
  const { ecUsage, ecUsageDataLoading, getEcUsage } = ecUsageContext;
  const [sortedUsageList, setSortedUsageList] = useState(null);

  useEffect(() => {
    if (ecUsage === null) {
      getEcUsage();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ecUsage !== null) {
      const sortedList = ecUsage.sort((a, b) => {
        return b.usage - a.usage;
      });
      // eslint-disable-next-line
      setSortedUsageList(sortedList);
    }
  }, [ecUsage]);

  return (
    <div className="container">
      <div className="row bg-secondary rounded mt-2">
        <h1 className="mx-auto text-white">EC USAGE</h1>
      </div>
      <div className="row">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center" scope="col">
                Company
              </th>
              <th className="text-center" scope="col">
                Site
              </th>
              <th className="text-center" scope="col">
                Usage (GB)
              </th>
            </tr>
          </thead>
          <tbody>
            {!ecUsageDataLoading && sortedUsageList !== null ? (
              sortedUsageList.map((connection) => (
                <EcUsageList connection={connection} key={connection.id} />
              ))
            ) : (
              <Fragment>
                <tr className="text-center">
                  <td></td>
                  <td
                    className="spinner-border text-secondary"
                    role="status"
                  ></td>
                  <td></td>
                </tr>
                <tr className="text-center">
                  <td></td>
                  <td className="text-center font-weight-bolder">
                    LOADING DATA
                  </td>
                  <td></td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EcUsage;
