import React from "react";

const EcFullStatusList = (props) => {
  const { id, Company, name, status } = props.connection;

  if (status === "online") {
    return (
      <tr className="bg-success text-white">
        <td className="border text-center">{id}</td>
        <td className="border">{Company}</td>
        <td className="border">{name}</td>
        <td className="border text-center">{status}</td>
      </tr>
    );
  } else {
    return (
      <tr className="bg-danger text-white">
        <td className="border text-center">{id}</td>
        <td className="border">{Company}</td>
        <td className="border">{name}</td>
        <td className="border text-center">{status}</td>
      </tr>
    );
  }
};

export default EcFullStatusList;
