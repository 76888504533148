import {
    GET_EC_STATUS,

  } from '../types';
  
  export default (state, action) => {
    switch (action.type) {
      case GET_EC_STATUS:
        return {
          ...state,
          ecConnections: action.payload,
          ecStatusLoading: false,
        };
      
      default:
        return state;
    }
  };
  