import React, { useContext, useState, useEffect } from "react";
import EcContext from "../../context/enterpriseConnect/ecContext";
import SdContext from "../../context/sdWan/sdContext";
import IpContext from "../../context/ipMonitoring/ipContext";
import AuthContext from "../../context/auth/authContext";

const StatusBar = () => {
  const sdContext = useContext(SdContext);
  const ecContext = useContext(EcContext);
  const ipContext = useContext(IpContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { ecConnections } = ecContext;
  const { sdConnections } = sdContext;
  const { ipConnections } = ipContext;
  const [down, setDown] = useState(0);
  const [issues, setIssues] = useState(0);
  const [up, setUp] = useState(0);
  const [ipDown, setIpDown] = useState(0);
  const [ecDown, setEcDown] = useState(0);
  const [sdDown, setSdDown] = useState(0);
  const [ipUp, setIpUp] = useState(0);
  const [ecUp, setEcUp] = useState(0);
  const [sdUp, setSdUp] = useState(0);
  const [sdIssues, setSdIssues] = useState(0);
  const [ipIssues, setIpIssues] = useState(0);

  useEffect(() => {
    if (sdConnections !== null) {
      const sdDown = sdConnections.filter(
        (site) => site.site_status === "Site Offline"
      ).length;
      const sdIssues = sdConnections.filter(
        (site) => site.site_status === "Circuit Issues"
      ).length;
      const sdUp = sdConnections.filter(
        (site) => site.site_status === "Site Healthy"
      ).length;

      setSdDown(sdDown);
      setSdIssues(sdIssues);
      setSdUp(sdUp);
    }
  }, [sdConnections]);

  useEffect(() => {
    if (ipConnections !== null) {
      const ipDown = ipConnections.filter((site) => site.status === "Fail")
        .length;
      const ipUp = ipConnections.filter((site) => site.status === "Pass")
        .length;
      const ipIssues = ipConnections.filter((site) => site.status === "Warning")
        .length;

      setIpDown(ipDown);
      setIpUp(ipUp);
      setIpIssues(ipIssues);
    }
  }, [ipConnections]);

  useEffect(() => {
    if (ecConnections !== null) {
      const ecDown = ecConnections.filter((site) => site.status === "offline")
        .length;
      const ecUp = ecConnections.filter((site) => site.status === "online")
        .length;
      setEcDown(ecDown);
      setEcUp(ecUp);
    }
  }, [ecConnections]);

  useEffect(() => {
    setDown(0);
    setUp(0);
    setIssues(0);
    const newDownTotal = ecDown + ipDown + sdDown;
    const newUpTotal = ecUp + ipUp + sdUp;
    const newIssueTotal = ipIssues + sdIssues;
    setDown(newDownTotal);
    setUp(newUpTotal);
    setIssues(newIssueTotal);
  }, [ecDown, ipDown, sdDown, ecUp, ipUp, sdUp, ipIssues, sdIssues]);

  if (user !== null) {
    return (
      <div className="container-fluid">
        <div className="row px-3">
          <div className="summary__badges ">
            <button type="button" className="btn btn-danger summary-badges ">
              DOWN{" "}
              <span className="badge badge-light">
                {down !== undefined && down !== null ? down : "X"}
              </span>
            </button>
            <button type="button" className="btn btn-warning summary-badges">
              ISSUES{" "}
              <span className="badge badge-light">
                {issues !== undefined && issues !== null ? issues : "X"}
              </span>
            </button>
            <button type="button" className="btn btn-success summary-badges">
              UP{" "}
              <span className="badge badge-light">
                {up !== undefined && up !== null ? up : "X"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default StatusBar;
