import React from "react";

const ListNoteItem = (props) => {
  const { kb, editMode, viewMode, deleteTheKb } = props;

  function formatDate(string) {
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <tr>
      <td className="text-center pointer" onClick={() => viewMode(kb._id)}>
        {kb.Title}{" "}
      </td>
      <td className="text-center">{kb.Author} </td>
      <td className="text-center">{formatDate(kb.createdAt)} </td>
      <td className="text-right ">
        <span>
          <i
            className="fa fa-eye text-success pointer mr-3"
            aria-hidden="true"
            onClick={() => viewMode(kb._id)}
          ></i>
        </span>
        <span>
          <i
            className="fa fa-pencil-square-o text-warning pointer mr-3"
            aria-hidden="true"
            onClick={() => editMode(kb._id)}
          ></i>
        </span>
        <span
          className="text-danger font-weight-bolder pointer mr-2"
          data-toggle="modal"
          data-target="#noteDeleteConfirmationModal"
          onClick={() => deleteTheKb(kb._id)}
        >
          X
        </span>
      </td>
    </tr>
  );
};

export default ListNoteItem;
