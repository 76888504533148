import React, { useState, useContext, useEffect } from "react";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import "../../css/login.css";

const Login = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const {
    loginUser,
    error,
    clearErrors,
    isAuthenticated,
    loadUser,
  } = authContext;

  const { setAlert } = alertContext;
  const [user, setUser] = useState({
    username: "",
    Password: "",
  });

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser();
    }
    if (isAuthenticated) {
      props.history.push("/dashboard");
    }
    if (error === "Invalid Credentials") {
      setAlert(error, "danger");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props]);

  const { username, Password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === "" || Password === "") {
      setAlert("Please fill in all fields", "danger");
    } else {
      loginUser({
        username,
        Password,
      });
    }
  };

  return (
    <div className="container login-background">
      <div className="card login-div">
        <h1 className="title">LOGIN</h1>
        <form onSubmit={onSubmit}>
          <div className="fields">
            <div className="username">
              <svg fill="#999" class="svg-icon" viewBox="0 0 20 20">
                <path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path>
              </svg>
              <input
                type="username"
                name="username"
                placeholder="username"
                value={username}
                onChange={onChange}
                required
              />
            </div>
            <div className="password">
              <svg fill="#999" viewBox="0 0 1024 1024">
                <path
                  class="path1"
                  d="M742.4 409.6h-25.6v-76.8c0-127.043-103.357-230.4-230.4-230.4s-230.4 103.357-230.4 230.4v76.8h-25.6c-42.347 0-76.8 34.453-76.8 76.8v409.6c0 42.347 34.453 76.8 76.8 76.8h512c42.347 0 76.8-34.453 76.8-76.8v-409.6c0-42.347-34.453-76.8-76.8-76.8zM307.2 332.8c0-98.811 80.389-179.2 179.2-179.2s179.2 80.389 179.2 179.2v76.8h-358.4v-76.8zM768 896c0 14.115-11.485 25.6-25.6 25.6h-512c-14.115 0-25.6-11.485-25.6-25.6v-409.6c0-14.115 11.485-25.6 25.6-25.6h512c14.115 0 25.6 11.485 25.6 25.6v409.6z"
                ></path>
              </svg>
              <input
                type="Password"
                name="Password"
                value={Password}
                placeholder="password"
                onChange={onChange}
                required
              />
            </div>
            <div className="row">
              <button type="submit" value="LOGIN" className="signin-button">
                Submit
              </button>
              <div className="link">
                <a href="/forgotPassword">Forgot Password</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
