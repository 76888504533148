import React, { Fragment, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./techNotes.css";

const CreateNote = (props) => {
  const { cancelInput, createKb, doneCreating } = props;
  const [newNote, setNewNote] = useState();
  const [newNoteBody, setNewNoteBody] = useState();

  const on_change = (e, editor) => {
    const data = editor.getData();
    setNewNoteBody(data);
  };

  const onChange = (e) => {
    setNewNote({ ...newNote, [e.target.id]: e.target.value });
  };

  const theEditorConfig = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
    link: {
      addTargetToExternalLinks: true,
    },
    mediaEmbed: {
      removeProviders: [
        "instagram",
        "twitter",
        "googleMaps",
        "flickr",
        "facebook",
        "youtube",
        "vimeo",
        "spotify",
        "dailymotion",
      ],
    },
  };

  const AddNote = () => {
    const theNote = {
      Title: newNote.Title,
      Note: newNoteBody,
      Author: "Glenn Taggart",
    };

    createKb(theNote);
    doneCreating();
  };

  return (
    <Fragment>
      <h3 className="text-center font-weight-bold">CREATE TECHNICAL NOTE</h3>
      <div className="container border shadow my-5">
        <form>
          <div className="form-group mt-3">
            <label htmlFor="Title" className="font-weight-bold">
              TOPIC
            </label>
            <input
              type="text"
              className="form-control"
              id="Title"
              onChange={onChange}
            />
          </div>
          <div className="form-group newNoteBody">
            <CKEditor
              config={theEditorConfig}
              data={newNoteBody}
              editor={ClassicEditor}
              onChange={on_change}
              id="newNoteBody"
              maxLength="5000"
            />
          </div>
        </form>
        <div className="buttons mb-3">
          <div className="row">
            <div className="col-lg-2">
              <button className="btn btn-danger" onClick={() => cancelInput()}>
                CANCEL
              </button>
            </div>
            <div className="col-lg-8"></div>
            <div className="col-lg-2 text-right">
              <button className="btn btn-success" onClick={AddNote}>
                {" "}
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateNote;
