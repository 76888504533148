import React, { Fragment, useContext, useState } from "react";
import SdContext from "../../context/sdWan/sdContext";
import EcUsageContext from "../../context/ecUsage/ecUsageContext";
import AuthContext from "../../context/auth/authContext";
import NavContext from "../../context/navigation/navContext";
import Logo from "../images/logo.png";
import "../../css/NavBar.css";

const NavBar = () => {
  const sdContext = useContext(SdContext);

  const ecUsageContext = useContext(EcUsageContext);
  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const { clearCurrentSdwan } = sdContext;
  const { clearEcUsage } = ecUsageContext;
  const { logoutUser, isAuthenticated, user } = authContext;
  const {
    loadDashboard,
    loadMosScorePage,
    loadVoiceStatusPage,
    loadWireless,
    loadSdWan,
    loadIp,
    showUsers,
    loadUnreachablePage,
    loadSwitchoversPage,
    loadTechNotesPage,
  } = navContext;

  const [mobileMenu, setMobileMenu] = useState(false);

  const logoutTheUser = () => {
    logoutUser();
    loadDashboard();
  };

  const clearCurrents = () => {
    clearCurrentSdwan();
    clearEcUsage();
  };

  const toggleMobileMenu = () => {
    if (mobileMenu) {
      setMobileMenu(false);
    } else {
      setMobileMenu(true);
    }
  };

  const loggedInNavBar = (
    <header className="main-header">
      <button className="toggle-button" onClick={toggleMobileMenu}>
        <span className="toggle-button__bar"></span>
        <span className="toggle-button__bar"></span>
        <span className="toggle-button__bar"></span>
      </button>
      <a
        href="/"
        onClick={loadDashboard}
        className={
          mobileMenu ? "main-header__brand hide" : "main-header__brand"
        }
      >
        <img src={Logo} alt="SINGLE PANE" />
      </a>
      <button
        className={mobileMenu ? "navbar-toggler hide" : "navbar-toggler"}
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <nav className="main-nav">
        <ul className="main-nav__items">
          <li
            className="main-nav__item nav-pointer"
            onClick={() => {
              loadDashboard();
              clearCurrents();
            }}
          >
            HOME
          </li>
          <li className="main-nav__item">
            <p
              onClick={() => {
                loadWireless();
                clearCurrents();
              }}
            >
              ENT CONN
            </p>
          </li>
          <li className="main-nav__item">
            <p
              onClick={() => {
                loadIp();
                clearCurrents();
              }}
            >
              INTERNET
            </p>
          </li>
          <li className="main-nav__item">
            <p
              onClick={() => {
                loadSdWan();
                clearCurrents();
              }}
            >
              SD WAN
            </p>
          </li>
          <li className="main-nav__item dropdown">
            <p className="dropbtn">
              VOICE <i className="fa fa-caret-down ml-1"></i>{" "}
            </p>

            <div className="dropdown-content">
              <p
                onClick={() => {
                  loadMosScorePage();
                  clearCurrents();
                }}
              >
                MOS LIST
              </p>
              <p
                onClick={() => {
                  loadVoiceStatusPage();
                  clearCurrents();
                }}
              >
                MOS AVG
              </p>
              <p
                onClick={() => {
                  loadSwitchoversPage();
                  clearCurrents();
                }}
              >
                SWITCHOVERS
              </p>
              <p
                onClick={() => {
                  loadUnreachablePage();
                  clearCurrents();
                }}
              >
                UNREACHABLES
              </p>
            </div>
          </li>
          <li className="main-nav__item">
            <p
              onClick={() => {
                loadTechNotesPage();
                clearCurrents();
              }}
            >
              TECH NOTES
            </p>
          </li>
        </ul>
      </nav>
      <nav className={mobileMenu ? "mobile-nav open" : "mobile-nav"}>
        <ul className="mobile-nav__items">
          <li
            className="mobile-nav__item"
            onClick={() => {
              loadDashboard();
              clearCurrents();
              toggleMobileMenu();
            }}
          >
            DASHBOARD <span className="sr-only">(current)</span>
          </li>
          <li
            className="mobile-nav__item"
            onClick={() => {
              loadWireless();
              clearCurrents();
              toggleMobileMenu();
            }}
          >
            ENT CONN
          </li>
          <li
            className="mobile-nav__item"
            onClick={() => {
              loadIp();
              clearCurrents();
              toggleMobileMenu();
            }}
          >
            INTERNET
          </li>
          <li
            className="mobile-nav__item"
            onClick={() => {
              loadSdWan();
              clearCurrents();
              toggleMobileMenu();
            }}
          >
            SD WAN
          </li>
          <li className="mobile-nav__item">
            <p
              onClick={() => {
                loadMosScorePage();
                clearCurrents();
                toggleMobileMenu();
              }}
            >
              V-SCORES
            </p>
          </li>
          <li className="mobile-nav__item">
            <p
              onClick={() => {
                loadVoiceStatusPage();
                clearCurrents();
                toggleMobileMenu();
              }}
            >
              V-LIST
            </p>
          </li>
          <li className="mobile-nav__item">
            <p
              onClick={() => {
                loadSwitchoversPage();
                clearCurrents();
                toggleMobileMenu();
              }}
            >
              V-Switchovers
            </p>
          </li>
          <li className="mobile-nav__item">
            <p
              onClick={() => {
                loadUnreachablePage();
                clearCurrents();
                toggleMobileMenu();
              }}
            >
              V-Unreachables
            </p>
          </li>
          <li className="mobile-nav__item">
            <p
              onClick={() => {
                loadTechNotesPage();
                clearCurrents();
              }}
            >
              TECH NOTES
            </p>
          </li>
          {user !== null && user.Role === "Admin" ? (
            <li
              className="mobile-nav__item"
              type="button"
              onClick={() => {
                showUsers();
                clearCurrents();
                toggleMobileMenu();
              }}
            >
              USERS
            </li>
          ) : (
            <li></li>
          )}
          <li
            className="mobile-nav__item"
            onClick={() => {
              logoutTheUser();
              clearCurrents();
              toggleMobileMenu();
            }}
          >
            LOGOUT
          </li>
        </ul>
      </nav>

      {user !== null && user.Role === "Admin" ? (
        <button
          className="main-nav__admin_links"
          type="button"
          onClick={() => {
            showUsers();
            clearCurrents();
          }}
        >
          USERS
        </button>
      ) : (
        <div> </div>
      )}
      <button
        className="main-nav__logout"
        type="button"
        onClick={() => {
          logoutTheUser();
          clearCurrents();
        }}
      >
        LOGOUT
      </button>
    </header>
  );

  const loggedOutNavBar = (
    <div className="container-fluid bg-dark">
      <div className="row">
        <div className="col-md-12">
          <nav className="navbar navbar-expand-lg navbar-light bg-dark rounded">
            <div className="col-md-1">
              <img src={Logo} alt="" className="brand-login" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>{isAuthenticated ? loggedInNavBar : loggedOutNavBar}</Fragment>
  );
};

export default NavBar;
