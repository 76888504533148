import React from "react";

const MosScoresListing = (props) => {
  const { log, getMosScore, loadMosScoreDetail } = props;

  function formatDate(string) {
    var options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/New_York",
    };
    return new Date(string).toLocaleDateString("en-US", options);
  }

  const getDetail = () => {
    loadMosScoreDetail();
    getMosScore(log);
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={getDetail}>
        {formatDate(log.TimeStamp)}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.CallID}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.Site}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.Source}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.Destination}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.MOS}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.LP}
      </td>
      <td className="text-center pointer" onClick={getDetail}>
        {log.MPJ}
      </td>
    </tr>
  );
};

export default MosScoresListing;
