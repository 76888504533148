import React from "react";
import "../../../css/Dashboard.css";

const SdWanStatusList = (props) => {
  var muted = false;
  const {
    site_id,
    company_name,
    site_name,
    site_status,
    wan1_status,
    wan2_status,
  } = props.connection;

  const { alerts } = props;

  const { loadSdWanProfile, getCurrentSdWan } = props;

  const getSdWanProfile = () => {
    loadSdWanProfile(props.connection);
    getCurrentSdWan(props.connection);
  };

  const findAlert = alerts.find(
    (site) => site.Asset_id === `SDWAN${site_id}` && site.Active === "true"
  );

  if (
    findAlert !== undefined &&
    findAlert.Alert === "false" &&
    findAlert.Active === "true"
  ) {
    muted = true;
  }
  if (muted === false) {
    if (site_status === "Site Healthy") {
      return null;
    } else if (site_status === "Circuit Issues" && wan1_status === "Healthy") {
      return (
        <tr className="bg-warning text-white">
          <td className="nav-pointer dashboard-data" onClick={getSdWanProfile}>
            <div className="row ml-2 font-weight-bold text-dark dashboard-item">
              {company_name}
            </div>
            <div className="row ml-2 dashboard-item">{site_name}</div>
          </td>
        </tr>
      );
    } else if (
      site_status === "Circuit Issues" &&
      wan1_status === "Circuit Down"
    ) {
      return (
        <tr className="bg-info text-white">
          <td className="nav-pointer dashboard-data" onClick={getSdWanProfile}>
            <div className="row ml-2 font-weight-bold text-dark dashboard-item">
              {company_name}
            </div>
            <div className="row ml-2 dashboard-item">{site_name}</div>
          </td>
        </tr>
      );
    } else if (
      site_status === "Circuit Issues" &&
      wan1_status === "Issues" &&
      wan2_status === "Issues"
    ) {
      return (
        <tr className="bg-warning text-white">
          <td className="nav-pointer dashboard-data" onClick={getSdWanProfile}>
            <div className="row ml-2  font-weight-bold text-dark dashboard-item">
              {company_name}
            </div>
            <div className="row ml-2 dashboard-item">{site_name}</div>
          </td>
        </tr>
      );
    } else if (
      site_status === "Circuit Issues" &&
      wan1_status === "Issues" &&
      wan2_status === "Healthy"
    ) {
      return (
        <tr className="bg-warning text-white">
          <td className="nav-pointer dashboard-data" onClick={getSdWanProfile}>
            <div className="row ml-2 font-weight-bold text-dark dashboard-item">
              {company_name}
            </div>
            <div className="row ml-2 dashboard-item">{site_name}</div>
          </td>
        </tr>
      );
    } else if (site_status === "Site Offline") {
      return (
        <tr className="bg-danger text-white">
          <td className="nav-pointer dashboard-data" onClick={getSdWanProfile}>
            <div className="row ml-2 font-weight-bold text-dark dashboard-item">
              {company_name}
            </div>
            <div className="row ml-2 dashboard-item">{site_name}</div>
          </td>
        </tr>
      );
    }
  }
  if (muted === true) {
    return null;
  }
};

export default SdWanStatusList;
