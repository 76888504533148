import React, { useReducer } from "react";
import axios from "axios";
import SdContext from "./sdContext";
import ecReducer from "./sdReducer";

import {
  GET_SD_STATUS,
  LOAD_CURRENT_SDWAN,
  CLEAR_CURRENT_SDWAN,
} from "../types";

const SdState = (props) => {
  const initialstate = {
    sdConnections: null,
    sdStatusLoading: true,
    currentSdwan: null,
  };

  const [state, dispatch] = useReducer(ecReducer, initialstate);

  // GET EC STAUS UPDATE
  const getSdStatus = async () => {
    try {
      const status = await axios.post("/api/v1/sdWanStatus");

      dispatch({
        type: GET_SD_STATUS,
        payload: status.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const loadCurrentSdwan = (sdwan) => {
    dispatch({
      type: LOAD_CURRENT_SDWAN,
      payload: sdwan,
    });
  };

  const clearCurrentSdwan = () => {
    dispatch({
      type: CLEAR_CURRENT_SDWAN,
    });
  };

  return (
    <SdContext.Provider
      value={{
        sdConnections: state.sdConnections,
        sdStatusLoading: state.sdStatusLoading,
        currentSdwan: state.currentSdwan,
        getSdStatus,
        loadCurrentSdwan,
        clearCurrentSdwan,
      }}
    >
      {props.children}
    </SdContext.Provider>
  );
};

export default SdState;
