import {
  GET_SD_STATUS,
  LOAD_CURRENT_SDWAN,
  CLEAR_CURRENT_SDWAN,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_SD_STATUS:
      return {
        ...state,
        sdConnections: action.payload,
        sdStatusLoading: false,
      };

    case LOAD_CURRENT_SDWAN:
      return {
        ...state,
        currentSdwan: action.payload,
      };

    case CLEAR_CURRENT_SDWAN:
      return {
        ...state,
        currentSdwan: null,
      };

    default:
      return state;
  }
};
