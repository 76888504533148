import React, { useContext, useEffect, useState } from "react";
import IpContext from "../../../context/ipMonitoring/ipContext";
import IpFullStatusList from "./IpFullStatusList";

const Ip = () => {
  const ipContext = useContext(IpContext);
  const { ipConnections, ipStatusLoading, getIpStatus } = ipContext;
  const [sitesUp, setSitesUp] = useState(null);
  const [sitesDown, setSitesDown] = useState(null);
  const [sitesUndetermined, setSitesUndetermined] = useState(null);
  const [sitesWarning, setSitesWarning] = useState(null);

  useEffect(() => {
    getIpStatus();

    setInterval(() => {
      getIpStatus();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ipConnections !== null) {
      var offline = ipConnections.filter((site) => {
        return site.status === "Fail";
      });
      var online = ipConnections.filter((site) => {
        return site.status === "Pass";
      });
      var undetermined = ipConnections.filter((site) => {
        return site.status === "Undetermined";
      });
      var warning = ipConnections.filter((site) => {
        return site.status === "Warning";
      });
      setSitesDown(offline);
      setSitesUp(online);
      setSitesUndetermined(undetermined);
      setSitesWarning(warning);
    }
  }, [ipConnections]);

  return (
    <div className="container">
      <div className="row bg-secondary rounded mt-2">
        <h1 className="mx-auto text-white ">INTERNET MONITORING STATUS</h1>
      </div>
      <div className="row">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th className="text-center" scope="col">
                Connection ID
              </th>
              <th className="text-center" scope="col">
                Connection
              </th>
              <th className="text-center" scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!ipStatusLoading && sitesDown !== null ? (
              sitesDown.map((connection) => (
                <IpFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
            {!ipStatusLoading && sitesWarning !== null ? (
              sitesWarning.map((connection) => (
                <IpFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
            {!ipStatusLoading && sitesUndetermined !== null ? (
              sitesUndetermined.map((connection) => (
                <IpFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
            {!ipStatusLoading && sitesUp !== null ? (
              sitesUp.map((connection) => (
                <IpFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ip;
