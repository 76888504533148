import React, { Fragment, useContext, useEffect } from "react";
import UserContext from "../../../context/users/userContext";
import UserList from "./UserList";

const UserPage = (props) => {
  const userContext = useContext(UserContext);
  const { users, getUsers, loading } = userContext;
  const { newUser, loadUserProfile } = props;

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const createUser = () => {
    newUser();
  };

  return (
    <Fragment>
      <div className="container">
        <h2 className="text-center">USER LIST</h2>
        <div className="text-right">
          <button className="btn btn-dark" onClick={createUser}>
            Create User
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Username</th>
              <th scope="col">Creation Date</th>
              <th scope="col">Role</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users !== null && !loading ? (
              users.map((user) => (
                <UserList
                  user={user}
                  key={user._id}
                  loadUserProfile={loadUserProfile}
                />
              ))
            ) : (
              <tr>
                <td>loading</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default UserPage;
