import { GET_ALERTS, DELETE_ALERT, EDIT_ALERT, GET_ALERT } from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case DELETE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert._id !== action.payload),
      };
    case EDIT_ALERT:
      return {
        ...state,
        alerts: [
          action.payload,
          ...state.alerts.filter((alert) => alert._id !== action.payload._id),
        ],
        alert: action.payload,
      };
    case GET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
};
