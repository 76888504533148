import React from "react";

const AlertsList = (props) => {
  const {
    _id,
    Asset_id,
    Company,
    Site_Name,
    Alert,
    service_type,
  } = props.alert;
  const { editAlert, loadAlertDetail, getAlert, getSdWanConnection } = props;

  const muteAlert = () => {
    if (Alert === "true") {
      const updateAlert = {
        _id: _id,
        Alert: "false",
      };
      editAlert(updateAlert);
    } else {
      const updateAlert = {
        _id: _id,
        Alert: "true",
      };
      editAlert(updateAlert);
    }
  };

  const getAlertDetail = () => {
    loadAlertDetail();
    getAlert(props.alert);
    window.scroll(0, 0);
    if (service_type === "SD WAN") {
      const id = parseInt(Asset_id.split("SDWAN", 2)[1]);
      getSdWanConnection(id);
    }
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={getAlertDetail}>
        {Asset_id}
      </td>
      <td className="text-center pointer" onClick={getAlertDetail}>
        {Company}
      </td>
      <td className="text-center pointer" onClick={getAlertDetail}>
        {Site_Name}
      </td>
      <td className="text-center">{service_type}</td>

      <td>
        {Alert === "true" ? (
          <button
            className="btn btn-success text-light alert-mute-btn"
            onClick={muteAlert}
            aria-hidden="true"
          >
            MUTE
          </button>
        ) : (
          <button
            className="btn btn-danger text-light alert-mute-btn"
            onClick={muteAlert}
            aria-hidden="true"
          >
            MUTED
          </button>
        )}
      </td>
    </tr>
  );
};

export default AlertsList;
