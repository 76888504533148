import React from "react";
import AlertSdWanProfile from "./AlertSdWanProfile";

const AlertDetail = (props) => {
  const {
    Active,
    Alert,
    Asset_id,
    Company,
    Site_Name,
    _id,
    createdAt,
    service_type,
    updatedAt,
  } = props.alert;

  const { editAlert, loadAlertsPage, currentSdwan, clearCurrentSdwan } = props;

  const muteAlert = () => {
    if (Alert === "true") {
      const updateAlert = {
        _id: _id,
        Alert: "false",
      };
      editAlert(updateAlert);
    } else {
      const updateAlert = {
        _id: _id,
        Alert: "true",
      };
      editAlert(updateAlert);
    }
  };

  const backButtonPress = () => {
    loadAlertsPage();
    clearCurrentSdwan();
  };

  return (
    <div className="container border">
      <div className="row mt-2">
        <div className="col-2">
          <button
            className="btn btn-danger text-light alert-mute-btn"
            onClick={backButtonPress}
            aria-hidden="true"
          >
            BACK
          </button>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-10 bg-warning">
          <h3 className="font-weight-bolder ml-5 pt-2 text-center">
            ALERT DETAIL
          </h3>
        </div>
        <div className="col-2 bg-warning">
          {Alert === "true" ? (
            <button
              className="btn btn-success text-light alert-mute-btn m-1"
              onClick={muteAlert}
              aria-hidden="true"
            >
              MUTE
            </button>
          ) : (
            <button
              className="btn btn-danger text-light alert-mute-btn m-1"
              onClick={muteAlert}
              aria-hidden="true"
            >
              MUTED
            </button>
          )}
        </div>
      </div>
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-6">
            <table className="table table-striped border">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">ALERT INFORMATION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">COMPANY NAME</th>
                  <td>{Company}</td>
                </tr>
                <tr>
                  <th scope="row">SITE</th>
                  <td>{Site_Name}</td>
                </tr>
                <tr>
                  <th scope="row">SERVICE TYPE</th>
                  <td>{service_type}</td>
                </tr>
                <tr>
                  <th scope="row">CREATED AT</th>
                  <td>{createdAt}</td>
                </tr>
                <tr>
                  <th scope="row">MODIFIED AT</th>
                  <td>{updatedAt}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            <table className="table table-striped">
              <thead className="thead">
                <tr>
                  <th scope="col" className="bg-dark text-white"></th>
                  <th scope="col" className="bg-dark text-white"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">ALERT ID</th>
                  <td>{_id}</td>
                </tr>
                <tr>
                  <th scope="row">ASSET ID</th>
                  <td>{Asset_id}</td>
                </tr>
                <tr>
                  <th scope="row">ACTIVE</th>
                  <td>{Active}</td>
                </tr>
                <tr>
                  <th scope="row">ALERT</th>
                  <td>{Alert}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {service_type === "SD WAN" ? (
          <AlertSdWanProfile currentSdwan={currentSdwan} />
        ) : (
          <div> </div>
        )}
      </div>
    </div>
  );
};

export default AlertDetail;
