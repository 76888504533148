import React from "react";

const EcUsageList = (props) => {
  const { company, name, usage } = props.connection;

  return (
    <tr>
      <td className="text-center">{company}</td>
      <td className="text-center">{name}</td>
      <td className="text-center">{usage}</td>
    </tr>
  );
};

export default EcUsageList;
