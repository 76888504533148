import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserContext from "../../../context/users/userContext";

function formatDate(string) {
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return new Date(string).toLocaleDateString([], options);
}

const AdminUsersList = (props) => {
  const userContext = useContext(UserContext);
  const { deleteUser, getUser } = userContext;
  const { loadUserProfile, user } = props;
  const { _id, first_name, last_name, username, Role, createdAt } = user;
  const formatedDate = formatDate(createdAt);

  const deleteTheUser = (e) => {
    deleteUser(_id);
  };

  const viewTheUser = () => {
    getUser(_id);
    loadUserProfile();
  };

  return (
    <tr>
      <td onClick={viewTheUser}>
        {first_name} {last_name}
      </td>
      <td onClick={viewTheUser}>{username}</td>
      <td>{formatedDate}</td>
      <td>{Role}</td>
      <td className="text-right">
        <i
          className="fa fa-trash"
          title="Delete User"
          onClick={deleteTheUser}
          aria-hidden="true"
        ></i>
      </td>
    </tr>
  );
};

AdminUsersList.propTypes = {
  user: PropTypes.object.isRequired,
};
export default AdminUsersList;
