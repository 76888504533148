import React from "react";
import ListNoteItem from "./ListNoteItem";

const ListNotes = (props) => {
  const { kbs, editMode, viewMode, deleteTheKb } = props;

  return (
    <div className="container">
      <table className="table table-striped table-hover border">
        <thead className="thead">
          <tr>
            <th scope="col" className="text-center">
              TITLE
            </th>
            <th scope="col" className="text-center">
              AUTHOR
            </th>
            <th scope="col" className="text-center">
              PUBLISHED
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {kbs !== null
            ? kbs.map((kb) => (
                <ListNoteItem
                  kb={kb}
                  key={kb._id}
                  editMode={editMode}
                  viewMode={viewMode}
                  deleteTheKb={deleteTheKb}
                />
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default ListNotes;
