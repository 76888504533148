import React, { useReducer, useContext } from "react";
import UserContext from "./userContext";
import axios from "axios";
import usersReducer from "./usersReducer";
import AlertContext from "../alert/alertContext";

import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  CLEAR_CURRENT,
  GET_USER,
  GET_USERS,
} from "../types";

const UserState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    user: null,
    users: null,
    current: null,
    clearCurrent: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(usersReducer, initialstate);

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Get Users
  const getUsers = async (id) => {
    try {
      const res = await axios.get(`/api/user/`);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create USER
  const createUser = async (data) => {
    axios.post("/api/user", data, config);
    setAlert("User Created", "success");
    try {
      dispatch({
        type: CREATE_USER,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getUser = async (e) => {
    try {
      const res = await axios.get(`/api/user/${e}`);

      const updatedData = {
        _id: res.data._id,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        username: res.data.username,
        email_address: res.data.email_address,
        mobile_number: res.data.mobile_number,
        Role: res.data.Role,
      };
      dispatch({
        type: GET_USER,
        payload: updatedData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editUser = (e) => {
    if (e.newPassword === "") {
      const updateData = {
        First_Name: e.First_Name,
        Last_Name: e.Last_Name,
        Username: e.Username,
        Role: e.Role,
      };
      try {
        axios.put(`/api/user/${e.userID}`, updateData, config);
        dispatch({
          type: EDIT_USER,
          payload: updateData,
        });
        setAlert("User Updated", "success");
      } catch (err) {
        console.log(err);
      }
    } else {
      const updateData = {
        First_Name: e.First_Name,
        Last_Name: e.Last_Name,
        Username: e.Username,
        Role: e.Role,
        Password: e.newPassword,
      };

      try {
        axios.put(`/api/user/${e.userID}`, updateData, config);
        dispatch({
          type: EDIT_USER,
          payload: updateData,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteUser = (e) => {
    try {
      axios.delete(`/api/user/${e}`);
      dispatch({
        type: DELETE_USER,
        payload: e,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearCurrent = (e) => {
    dispatch({
      type: CLEAR_CURRENT,
    });
  };

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        user: state.user,
        current: state.current,
        loading: state.loading,
        clearCurrent,
        createUser,
        editUser,
        deleteUser,
        getUser,
        getUsers,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
