import {
  GET_TECH_NOTES,
  GET_TECH_NOTE,
  CREATE_TECH_NOTE,
  UPDATE_TECH_NOTE,
  DELETE_TECH_NOTE,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_TECH_NOTES:
      return {
        ...state,
        kbs: action.payload,
      };
    case GET_TECH_NOTE:
      return {
        ...state,
        kb: action.payload,
      };
    case UPDATE_TECH_NOTE:
      return {
        ...state,
        kbs: [
          action.payload,
          ...state.kbs.filter((kb) => kb._id !== action.payload._id),
        ],
      };
    case CREATE_TECH_NOTE:
      return {
        ...state,
        kbs: [action.payload, ...state.kbs],
      };
    case DELETE_TECH_NOTE:
      return {
        ...state,
        kbs: state.kbs.filter((kb) => kb._id !== action.payload),
      };

    default:
      return state;
  }
};
