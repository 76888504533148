import React from "react";

const EcFullStatusList = (props) => {
  const {
    site_id,
    company_name,
    site_name,
    site_status,
    wan1_status,
    wan2_status,
    wan3_status,
    wan4_status,
  } = props.connection;

  const { loadSdWanProfile, getCurrentSdWan } = props;

  const getSdWanProfile = () => {
    loadSdWanProfile(props.connection);
    getCurrentSdWan(props.connection);
  };

  if (site_status === "Site Healthy") {
    return (
      <tr className="bg-success text-white border onClick={getSdWanProfile}">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  } else if (site_status === "Circuit Issues" && wan1_status === "Healthy") {
    return (
      <tr className="bg-warning text-white border">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  } else if (
    site_status === "Circuit Issues" &&
    wan1_status === "Issues" &&
    wan2_status === "Issues"
  ) {
    return (
      <tr className="bg-warning text-white border">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  } else if (
    site_status === "Circuit Issues" &&
    wan1_status === "Issues" &&
    wan2_status === "Healthy"
  ) {
    return (
      <tr className="bg-warning text-white border">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  } else if (
    site_status === "Circuit Issues" &&
    wan1_status === "Circuit Down"
  ) {
    return (
      <tr className="bg-info text-white border">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  } else if (site_status === "Site Offline") {
    return (
      <tr className="bg-danger text-white border">
        <td
          className="border text-center nav-pointer"
          onClick={getSdWanProfile}
        >
          {site_id}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {company_name}
        </td>
        <td className="border nav-pointer" onClick={getSdWanProfile}>
          {site_name}
        </td>
        <td className="border text-center">{site_status}</td>
        <td className="border text-center">{wan1_status}</td>
        <td className="border text-center">{wan2_status}</td>
        <td className="border text-center">{wan3_status}</td>
        <td className="border text-center">{wan4_status}</td>
      </tr>
    );
  }
};

export default EcFullStatusList;
