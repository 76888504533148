import React, { useState, useContext, useEffect } from "react";
import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import PasswordValidator from "../../utils/PasswordValidator";

const NewUser = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { showUsers } = props;
  const { setAlert } = alertContext;
  const { register, error, clearErrors } = authContext;

  useEffect(() => {
    if (error === "User Already exists") {
      setAlert(error, "danger");
      clearErrors();
    }

    // eslint-disable-next-line
  }, [error]);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    username: "",
    Role: "",
    Password: "",
    Password2: "",
    email_address: "",
  });

  const {
    first_name,
    last_name,
    mobile_number,
    Role,
    username,
    email_address,
    Password,
    Password2,
  } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (Password !== Password2) {
      setAlert("PASSWORDS DON'T MATCH", "danger");
    } else {
      if (PasswordValidator(Password)) {
        register(user);
        setAlert(`User Named ${first_name} created successfully`, "success");
        setTimeout(() => {
          showUsers();
        }, 500);
      } else {
        setAlert("Invalid Password - See Password rules");
      }
    }
  };

  return (
    <div className="container">
      <h1>ADD USER</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            className="form-control"
            type="text"
            name="username"
            value={username}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="first_name">First Name</label>
          <input
            className="form-control"
            type="text"
            name="first_name"
            value={first_name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="last_name">Last Name</label>
          <input
            className="form-control"
            type="text"
            name="last_name"
            value={last_name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile_number">Mobile Number</label>
          <input
            className="form-control"
            type="text"
            name="mobile_number"
            value={mobile_number}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email_address">Email Address</label>
          <input
            className="form-control"
            type="email_address"
            name="email_address"
            value={email_address}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Password">Password</label>
          <input
            className="form-control"
            type="Password"
            name="Password"
            value={Password}
            onChange={onChange}
            required
            minLength="8"
          />
        </div>
        <div className="form-group">
          <label htmlFor="Password2">Confirm Password</label>
          <input
            className="form-control"
            type="Password"
            name="Password2"
            value={Password2}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Role">Role</label>
          <select name="Role" className="form-control" onChange={onChange}>
            <option value={Role}></option>
            <option>Admin</option>
            <option>User</option>
          </select>
        </div>
        <p>
          Password Rules: Atleast 8 Characters Uppercase Character(s) Lowercase
          Character(s) Number(s) Special Character
        </p>
        <div className="my-4">
          <input
            type="submit"
            value="Register"
            className="btn btn-primary mr-3"
          />
          <button className="btn btn-danger mr-3" onClick={showUsers}>
            CANCEL
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewUser;
