import React, { Fragment } from "react";
import Ecstatus from "../modules/enterpriseConnect/EcStatus";
import Sdstatus from "../modules/sdWan/SdWanStatus";
import IpStatus from "../modules/internet/IpStatus";
import VoiceStatus from "../modules/voice/VoiceStatus";

const Dashboard = (props) => {
  const { loadSdWanProfile, loadCurrentSdwan, alerts, loadAlertsPage } = props;

  const getCurrentSdWan = (connection) => {
    loadCurrentSdwan(connection);
  };

  return (
    <Fragment>
      <div className="container-fluid bg-white">
        <div className="row ">
          <div className="col-lg-10"></div>
          <button
            className="btn btn-dark text-white font-weight-bold my-2 mr-2col-lg-2"
            onClick={loadAlertsPage}
          >
            MANAGE ALERTS
          </button>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="row mx-auto">
          <div className="col-12 col-xl-3">
            <Ecstatus alerts={alerts} />
          </div>
          <div className="col-12 col-xl-3 ">
            <IpStatus alerts={alerts} />
          </div>
          <div className="col-12 col-xl-3">
            <VoiceStatus alerts={alerts} />
          </div>
          <div className="col-12 col-xl-3 ">
            <Sdstatus
              loadSdWanProfile={loadSdWanProfile}
              getCurrentSdWan={getCurrentSdWan}
              alerts={alerts}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
