import React, { useReducer } from "react";
import axios from "axios";
import EcContext from "./ecContext";
import ecReducer from "./ecReducer";

import { GET_EC_STATUS } from "../types";

const EcState = (props) => {
  const initialstate = {
    ecConnections: null,
    ecStatusLoading: true,
  };

  const [state, dispatch] = useReducer(ecReducer, initialstate);

  // GET EC STAUS UPDATE
  const getEcStatus = async () => {
    try {
      const status = await axios.post("/api/v1/ecStatus/");

      dispatch({
        type: GET_EC_STATUS,
        payload: status.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <EcContext.Provider
      value={{
        ecConnections: state.ecConnections,
        ecStatusLoading: state.ecStatusLoading,
        getEcStatus,
      }}
    >
      {props.children}
    </EcContext.Provider>
  );
};

export default EcState;
