import React, { useReducer } from "react";
import LogsContext from "./logsContext";
import logsReducer from "./logsReducer";
import axios from "axios";
import {
  GET_MOS_LOGS,
  DELETE_MOS_LOG,
  GET_MOS_LOG,
  GET_UNREACHABLES,
  GET_SWITCHOVERS,
} from "../types";

// const config = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };

const LogsState = (props) => {
  const initialState = {
    mos_logs: null,
    mos_log: null,
    unreachables: null,
    switchovers: null,
  };

  const [state, dispatch] = useReducer(logsReducer, initialState);

  //   GET MOS SCORES
  const getMosScores = async () => {
    const res = await axios.get("/api/logs");
    dispatch({
      type: GET_MOS_LOGS,
      payload: res.data,
    });
  };

  const getUnreachables = async () => {
    const res = await axios.get("/api/unreachables/");
    dispatch({
      type: GET_UNREACHABLES,
      payload: res.data,
    });
  };
  const getSwitchovers = async () => {
    const res = await axios.get("/api/switchovers/");
    dispatch({
      type: GET_SWITCHOVERS,
      payload: res.data,
    });
  };

  //   GET MOS SCORES of Last Hours
  const getMosScoresLastHour = async () => {
    const res = await axios.get("/api/logs/lasthour");
    dispatch({
      type: GET_MOS_LOGS,
      payload: res.data,
    });
  };

  //   GET MOS SCORE
  const getMosScore = (score) => {
    dispatch({
      type: GET_MOS_LOG,
      payload: score,
    });
  };

  //   DELETE MOS SCORE
  const deleteMosScore = async (e) => {
    await axios.delete(`/api/logs/${e}`);
    dispatch({
      type: DELETE_MOS_LOG,
      payload: e,
    });
  };

  return (
    <LogsContext.Provider
      value={{
        mos_logs: state.mos_logs,
        mos_log: state.mos_log,
        unreachables: state.unreachables,
        switchovers: state.switchovers,
        getMosScores,
        deleteMosScore,
        getMosScore,
        getUnreachables,
        getSwitchovers,
        getMosScoresLastHour,
      }}
    >
      {props.children}
    </LogsContext.Provider>
  );
};

export default LogsState;
