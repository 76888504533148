import { LOAD_A_PAGE } from "../types";

export default (state, action) => {
  switch (action.type) {
    case LOAD_A_PAGE:
      return {
        ...state,
        step: action.payload,
      };

    default:
      return state;
  }
};
