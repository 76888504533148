import React, { Fragment, useContext, useEffect, useState } from "react";
import AlertsContext from "../../context/alerts/alertsContext";
import SdWanContext from "../../context/sdWan/sdContext";
import AlertsList from "../modules/alerts/AlertsList";

const Alerts = (props) => {
  const alertsContext = useContext(AlertsContext);
  const sdWanContext = useContext(SdWanContext);
  const { getAlerts, alerts, deleteAlert, editAlert } = alertsContext;
  const { sdConnections, loadCurrentSdwan } = sdWanContext;
  const [filterAlertsBy, setFilterAlertsBy] = useState("Active - UnMuted");
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const { loadAlertDetail, getAlert } = props;

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterAlertsBy === "Active") {
      const updatedAlertsList = alerts.filter(
        (alert) => alert.Active === "true"
      );
      setFilteredAlerts(updatedAlertsList);
    }
    if (filterAlertsBy === "Closed") {
      const updatedAlertsList = alerts.filter(
        (alert) => alert.Active === "false"
      );
      setFilteredAlerts(updatedAlertsList);
    }
    if (filterAlertsBy === "Active - Muted") {
      const updatedAlertsList = alerts.filter(
        (alert) => alert.Alert === "false" && alert.Active === "true"
      );
      setFilteredAlerts(updatedAlertsList);
    }
    if (filterAlertsBy === "Active - UnMuted") {
      const updatedAlertsList = alerts.filter(
        (alert) => alert.Alert === "true" && alert.Active === "true"
      );
      setFilteredAlerts(updatedAlertsList);
    }
    if (filterAlertsBy === "All") {
      setFilteredAlerts(alerts);
    }
  }, [alerts, filterAlertsBy]);

  const filterbytoggle = (view) => {
    setFilterAlertsBy(view);
  };

  const getSdWanConnection = (id) => {
    const connection = sdConnections.filter((wan) => wan.site_id === id);
    loadCurrentSdwan(connection);
  };

  return (
    <div className="container">
      <h2 className="mx-auto bg-warning text-dark font-weight-bold text-center my-3 ">
        MANAGE ALERTS
      </h2>
      <div className="input-group mb-2 ">
        <span className="font-weight-bold pt-2">VIEW: </span>
        <div className="input-group-append ml-2">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {filterAlertsBy}
          </button>
          <div className="dropdown-menu">
            <p className="dropdown-item" onClick={() => filterbytoggle("All")}>
              ALL ALERTS
            </p>
            <p
              className="dropdown-item"
              onClick={() => filterbytoggle("Active")}
            >
              Active
            </p>
            <p
              className="dropdown-item"
              onClick={() => filterbytoggle("Closed")}
            >
              Closed
            </p>
            <p
              className="dropdown-item"
              onClick={() => filterbytoggle("Active - Muted")}
            >
              Active - MUTED
            </p>
            <p
              className="dropdown-item"
              onClick={() => filterbytoggle("Active - UnMuted")}
            >
              Active - UNMUTED
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="text-center" scope="col">
                ASSET ID
              </th>
              <th className="text-center" scope="col">
                COMPANY
              </th>
              <th className="text-center" scope="col">
                SITE
              </th>
              <th className="text-center" scope="col">
                SERVICE
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredAlerts !== null ? (
              filteredAlerts.map((alert) => (
                <AlertsList
                  alert={alert}
                  getAlert={getAlert}
                  deleteAlert={deleteAlert}
                  editAlert={editAlert}
                  loadAlertDetail={loadAlertDetail}
                  getSdWanConnection={getSdWanConnection}
                  key={alert._id}
                />
              ))
            ) : (
              <Fragment>
                <tr className="text-center">
                  <td></td>
                  <td
                    className="spinner-border text-secondary"
                    role="status"
                  ></td>
                  <td></td>
                </tr>
                <tr className="text-center">
                  <td></td>
                  <td className="text-center font-weight-bolder">
                    LOADING DATA
                  </td>
                  <td></td>
                </tr>
              </Fragment>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Alerts;
