import {
  GET_MOS_LOGS,
  DELETE_MOS_LOG,
  GET_MOS_LOG,
  GET_UNREACHABLES,
  GET_SWITCHOVERS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_MOS_LOGS:
      return {
        ...state,
        mos_logs: action.payload,
      };
    case GET_UNREACHABLES:
      return {
        ...state,
        unreachables: action.payload,
      };
    case GET_SWITCHOVERS:
      return {
        ...state,
        switchovers: action.payload,
      };
    case DELETE_MOS_LOG:
      return {
        ...state,
        mos_logs: state.mos_logs.filter((log) => log._id !== action.payload),
      };
    case GET_MOS_LOG:
      return {
        ...state,
        mos_log: action.payload,
      };
    default:
      return state;
  }
};
