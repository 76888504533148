import React, { useContext, useEffect } from "react";
import IpContext from "../../../context/ipMonitoring/ipContext";
import IpStatusList from "./IpStatusList";

const IpStatus = (props) => {
  const ipContext = useContext(IpContext);
  const { ipConnections, ipStatusLoading, getIpStatus } = ipContext;
  const { alerts } = props;

  useEffect(() => {
    getIpStatus();

    setInterval(() => {
      getIpStatus();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="container border border-dark">
      <div className="row bg-dark mt-2">
        <h1 className="mx-auto dashboard-heading ">INTERNET</h1>
      </div>
      <div className="row">
        <table className="table">
          {/* <thead className="thead-dark">
            <tr>
              <th className="text-center dashboard-column-heading" scope="col">
                SITE
              </th>
            </tr>
          </thead> */}
          <tbody>
            {!ipStatusLoading && ipConnections !== null ? (
              ipConnections.map((connection) => (
                <IpStatusList
                  alerts={alerts}
                  connection={connection}
                  key={connection.id}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IpStatus;
