import {
    GET_IP_STATUS,

  } from '../types';
  
  export default (state, action) => {
    switch (action.type) {
      case GET_IP_STATUS:
        return {
          ...state,
          ipConnections: action.payload,
          ipStatusLoading: false,
        };
      
      default:
        return state;
    }
  };
  