import React, { useContext, useEffect } from "react";
import EcContext from "../../../context/enterpriseConnect/ecContext";
import EcStatusList from "./EcStatusList";

const EcStatus = (props) => {
  const ecContext = useContext(EcContext);
  const { ecConnections, ecStatusLoading, getEcStatus } = ecContext;
  const { alerts } = props;

  useEffect(() => {
    getEcStatus();

    setInterval(() => {
      getEcStatus();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  return (
    <div className="container border border-dark">
      <div className="row bg-warning mt-2">
        <h1 className="mx-auto dashboard-heading text-dark">ENT CONNECT</h1>
      </div>
      <div className="row">
        <table className="table">
          {/* <thead className="thead-dark">
            <tr>
              <th
                className="text-center bg-secondary dashboard-column-heading"
                scope="col"
              >
                SITE
              </th>
            </tr>
          </thead> */}
          <tbody>
            {!ecStatusLoading && ecConnections !== null ? (
              ecConnections.map((connection) => (
                <EcStatusList
                  connection={connection}
                  key={connection.id}
                  alerts={alerts}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EcStatus;
