import React, { useContext } from "react";
import UserContext from "../../../context/users/userContext";

const UserProfile = (props) => {
  const userContext = useContext(UserContext);
  const { clearCurrent } = userContext;
  const { showUsers, user } = props;

  const returnToUserList = () => {
    clearCurrent(user._id);
    showUsers();
  };

  if (user !== null) {
    return (
      <div className="container border">
        <div className="row">
          <h2 className="m-auto">USER PROFILE</h2>
        </div>
        <div className="container border">
          <table className="table">
            <thead className="thead"></thead>
            <tbody>
              <tr>
                <th scope="row">First Name</th>
                <td>{user.first_name}</td>
              </tr>
              <tr>
                <th scope="row">Last Name</th>
                <td>{user.last_name}</td>
              </tr>
              <tr>
                <th scope="row">Mobile Number</th>
                <td>{user.mobile_number}</td>
              </tr>
              <tr>
                <th scope="row">Email Address</th>
                <td>{user.email_address}</td>
              </tr>
              <tr>
                <th scope="row">Username</th>
                <td>{user.username}</td>
              </tr>
              <tr>
                <th scope="row">Role</th>
                <td>{user.Role}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="my-2">
          <button className="btn bg-warning" onClick={returnToUserList}>
            BACK
          </button>
        </div>
      </div>
    );
  } else {
    return <div className="col-md-4"> LOADING </div>;
  }
};

export default UserProfile;
