import React, { useReducer } from 'react';
import axios from 'axios';
import ipContext from './ipContext';
import ipReducer from './ipReducer';

import {
GET_IP_STATUS
} from '../types';



const IpState = (props) => {
  const initialstate = {
    ipConnections: null,
    ipStatusLoading: true,
  };

  const [state, dispatch] = useReducer(ipReducer, initialstate);



  // GET IP STAUS UPDATE
  const getIpStatus = async () => {
   try {
      const status = await axios.post('/api/v1/ping');

      dispatch({
        type: GET_IP_STATUS,
        payload: status.data,
      });
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <ipContext.Provider
      value={{
        ipConnections: state.ipConnections,
        ipStatusLoading: state.ipStatusLoading,
        getIpStatus,
      }}
    >
      {props.children}
    </ipContext.Provider>
  );
};

export default IpState;
