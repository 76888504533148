import React, { Fragment } from "react";
import ReactHtmlParser from "react-html-parser";

const ViewNote = (props) => {
  const { doneViewing, kb } = props;

  function formatDate(string) {
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return new Date(string).toLocaleDateString([], options);
  }

  if (kb !== null) {
    return (
      <Fragment>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <h3 className="font-weight-bold m-3">SUBJECT: {kb.Title}</h3>
          </div>
        </div>
        <div className="container border shadow">
          <div className="row mt-3 px-3">
            <div className="note-body">{ReactHtmlParser(kb.Note)}</div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-3">
              <button className="btn btn-danger" onClick={() => doneViewing()}>
                BACK
              </button>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-right font-weight-bold">
              {kb.Author}
              <span className="ml-2">{formatDate(kb.createdAt)}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else return null;
};

export default ViewNote;
