import React from "react";

const IpFullStatusList = (props) => {
  const { id, name, status } = props.connection;

  if (status === "Pass") {
    return (
      <tr className="bg-success text-white border">
        <td className="border text-center">{id}</td>
        <td className="border">{name}</td>
        <td className="text-align-center text-center">{status}</td>
      </tr>
    );
  } else if (status === "Fail") {
    return (
      <tr className="bg-danger text-white border">
        <td className="border text-center ">{id}</td>
        <td className="border">{name}</td>
        <td className="text-align-center text-center">{status}</td>
      </tr>
    );
  } else if (status === "Undetermined") {
    return (
      <tr className="bg-warning text-white">
        <td className="border text-center">{id}</td>
        <td className="border">{name}</td>
        <td className="text-align-center text-center">{status}</td>
      </tr>
    );
  } else if (status === "Warning") {
    return (
      <tr className="bg-warning text-white">
        <td className="border text-center">{id}</td>
        <td className="border">{name}</td>
        <td className="text-align-center text-center">{status}</td>
      </tr>
    );
  }
};

export default IpFullStatusList;
