import React, { useContext, useEffect, useState } from "react";
import SdContext from "../../../context/sdWan/sdContext";
import SdWanFullStatusList from "./SdWanFullStatusList";

const SdWan = (props) => {
  const sdContext = useContext(SdContext);
  const { sdConnections, sdStatusLoading, getSdStatus } = sdContext;
  const [offlineSites, setOfflineSites] = useState(null);
  const [sitesOnline, setSitesOnline] = useState(null);
  const [sitesWithIssues, setSitesWithIssues] = useState(null);
  const [sitesWithWan1Down, setSitesWithWan1Down] = useState(null);
  const { loadSdWanProfile, loadCurrentSdwan } = props;

  useEffect(() => {
    getSdStatus();

    setInterval(() => {
      getSdStatus();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sdConnections !== null) {
      var offline = sdConnections.filter((site) => {
        return site.site_status === "Site Offline";
      });
      var issues = sdConnections.filter((site) => {
        return (
          site.site_status === "Circuit Issues" &&
          site.wan1_status === "Healthy"
        );
      });
      var moreIssues = sdConnections.filter((site) => {
        return (
          site.site_status === "Circuit Issues" &&
          site.wan1_status === "Issues" &&
          site.wan2_status === "Issues"
        );
      });
      var moreIssues2 = sdConnections.filter((site) => {
        return (
          site.site_status === "Circuit Issues" &&
          site.wan1_status === "Issues" &&
          site.wan2_status === "Healthy"
        );
      });

      var wan1Down = sdConnections.filter((site) => {
        return (
          site.wan1_status === "Circuit Down" &&
          site.site_status === "Circuit Issues"
        );
      });
      var online = sdConnections.filter((site) => {
        return site.site_status === "Site Healthy";
      });

      var data1 = issues.concat(moreIssues);
      var data = data1.concat(moreIssues2);

      setOfflineSites(offline);
      setSitesWithIssues(data);
      setSitesWithWan1Down(wan1Down);
      setSitesOnline(online);
    }
  }, [sdConnections]);

  const getCurrentSdWan = (connection) => {
    loadCurrentSdwan(connection);
  };

  return (
    <div className="container">
      <div className="row bg-secondary rounded mt-2">
        <h1 className="mx-auto text-white">SD WAN STATUS</h1>
      </div>
      <div className="row">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th className="text-center" scope="col">
                Site ID
              </th>
              <th className="text-center" scope="col">
                Company
              </th>
              <th className="text-center" scope="col">
                Site
              </th>
              <th className="text-center" scope="col">
                Site Status
              </th>
              <th className="text-center" scope="col">
                WAN 1 Status
              </th>
              <th className="text-center" scope="col">
                WAN 2 Status
              </th>
              <th className="text-center" scope="col">
                WAN 3 Status
              </th>
              <th className="text-center" scope="col">
                WAN 4 Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!sdStatusLoading && offlineSites !== null ? (
              offlineSites.map((connection) => (
                <SdWanFullStatusList
                  connection={connection}
                  loadSdWanProfile={loadSdWanProfile}
                  getCurrentSdWan={getCurrentSdWan}
                  key={connection.site_id}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
            {!sdStatusLoading && offlineSites !== null ? (
              sitesWithWan1Down.map((connection) => (
                <SdWanFullStatusList
                  connection={connection}
                  loadSdWanProfile={loadSdWanProfile}
                  getCurrentSdWan={getCurrentSdWan}
                  key={connection.site_id}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
            {!sdStatusLoading && offlineSites !== null ? (
              sitesWithIssues.map((connection) => (
                <SdWanFullStatusList
                  connection={connection}
                  loadSdWanProfile={loadSdWanProfile}
                  getCurrentSdWan={getCurrentSdWan}
                  key={connection.site_id}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
            {!sdStatusLoading && sitesOnline !== null ? (
              sitesOnline.map((connection) => (
                <SdWanFullStatusList
                  connection={connection}
                  loadSdWanProfile={loadSdWanProfile}
                  getCurrentSdWan={getCurrentSdWan}
                  key={connection.site_id}
                />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SdWan;
