import React, { useEffect } from "react";

const UnreachableList = (props) => {
  const { unreachables, getUnreachables } = props;

  useEffect(() => {
    getUnreachables();
    // eslint-disable-next-line
  }, []);

  function formatDate(string) {
    var options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <div className="container">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col" className="text-center">
              DATE/TIME
            </th>
            <th scope="col" className="text-center">
              MESSAGE
            </th>
          </tr>
        </thead>
        <tbody>
          {unreachables !== null
            ? unreachables.map((log) => (
                <tr key={log._id}>
                  <td>{formatDate(log.createdAt)}</td>
                  <td>{log.message}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default UnreachableList;
