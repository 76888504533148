import React, { Fragment, useEffect, useState } from "react";
import MosScoresListing from "./MosScoresListing";

const MosScores = (props) => {
  const [displaySideNav, setDisplaySideNav] = useState(false);

  const {
    mos_logs,
    getMosScoresLastHour,
    getMosScore,
    loadMosScoreDetail,
  } = props;

  useEffect(() => {
    getMosScoresLastHour();
    // eslint-disable-next-line
  }, []);

  const toggleSideNav = () => {
    if (displaySideNav === true) {
      setDisplaySideNav(false);
    } else {
      setDisplaySideNav(true);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {displaySideNav ? (
          <div className="col-lg-2">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th>
                    <div className="text-right">
                      <i
                        className="fa fa-caret-left fa-3x text-warning"
                        onClick={toggleSideNav}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>MOS LOGS</th>
                </tr>
                <tr>
                  <th>MOS AVGS</th>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <i
            className="fa fa-caret-right fa-3x text-warning ml-2"
            onClick={toggleSideNav}
            aria-hidden="true"
          />
        )}
        <div className="col-lg-10">
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col" className="text-center">
                  DATE/TIME
                </th>
                <th scope="col" className="text-center">
                  CALL ID
                </th>
                <th scope="col" className="text-center">
                  SITE
                </th>
                <th scope="col" className="text-center">
                  SOURCE
                </th>
                <th scope="col" className="text-center">
                  DESTINATION
                </th>
                <th scope="col" className="text-center">
                  MOS
                </th>
                <th scope="col" className="text-center">
                  LOST PACKETS
                </th>
                <th scope="col" className="text-center">
                  JITTER
                </th>
              </tr>
            </thead>
            <tbody>
              {mos_logs !== null ? (
                mos_logs.map((log) => (
                  <MosScoresListing
                    key={log._id}
                    log={log}
                    getMosScore={getMosScore}
                    loadMosScoreDetail={loadMosScoreDetail}
                  />
                ))
              ) : (
                <Fragment>
                  <tr>
                    <th>LOADING</th>
                  </tr>
                </Fragment>
              )}
            </tbody>
          </table>
        </div>
        )
      </div>
    </div>
  );
};

export default MosScores;
