import React, { useReducer } from "react";
import axios from "axios";
import EcUsageContext from "./ecUsageContext";
import ecUsageReducer from "./ecUsageReducer";

import { GET_EC_USAGE, CLEAR_EC_USAGE } from "../types";

const EcUsageState = (props) => {
  const initialstate = {
    ecUsageDataLoading: true,
    ecUsage: null,
  };

  const [state, dispatch] = useReducer(ecUsageReducer, initialstate);

  // GET EC STAUS UPDATE
  const getEcUsage = async () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    // eslint-disable-next-line
    var searchDate = year + "-" + month + "-" + "01";

    var data = JSON.stringify({ start: searchDate });

    var config = {
      method: "post",
      url: "/api/v1/ecBandwidthUsage",
      headers: {
        content: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        dispatch({
          type: GET_EC_USAGE,
          payload: response.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const clearEcUsage = () => {
    dispatch({
      type: CLEAR_EC_USAGE,
    });
  };

  return (
    <EcUsageContext.Provider
      value={{
        ecUsageDataLoading: state.ecUsageDataLoading,
        ecUsage: state.ecUsage,
        getEcUsage,
        clearEcUsage,
      }}
    >
      {props.children}
    </EcUsageContext.Provider>
  );
};

export default EcUsageState;
