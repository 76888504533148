import React, { Fragment } from "react";

const SdWanProfile = (props) => {
  const { loadSdWan, currentSdwan, clearCurrentSdwan } = props;
  const {
    company_name,
    site_id,
    site_name,
    site_status,
    wan1_circuit_id,
    wan1_circuit_name,
    wan1_circuit_severity,
    wan1_circuit_type,
    wan1_status,
    wan1_wan_index,
    wan2_circuit_id,
    wan2_circuit_name,
    wan2_circuit_severity,
    wan2_circuit_type,
    wan2_status,
    wan2_wan_index,
    wan3_circuit_id,
    wan3_circuit_name,
    wan3_circuit_severity,
    wan3_circuit_type,
    wan3_status,
    wan3_wan_index,
    wan4_circuit_id,
    wan4_circuit_name,
    wan4_circuit_severity,
    wan4_circuit_type,
    wan4_status,
    wan4_wan_index,
  } = currentSdwan;

  const returnToSdWanList = () => {
    clearCurrentSdwan();
    loadSdWan();
  };

  window.scrollTo(0, 0);

  return (
    <div className="container border border-dark border-3 my-3 rounded shadow-lg">
      <div className="row">
        <h2 className="mx-auto">SD WAN - SITE DETAILS</h2>
      </div>
      <div className="row">
        <div className="col-lg-10"></div>
        <button
          className="btn btn-warning col-lg-1 m-3"
          onClick={returnToSdWanList}
        >
          back
        </button>
      </div>
      <div className="container border mb-3">
        <div className="row">
          <div className="col-lg-6 border">
            <table className="table table-striped border">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">SITE INFORMATION</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">SITE ID</th>
                  <td>{site_id}</td>
                </tr>
                <tr>
                  <th scope="row">COMPANY</th>
                  <td>{company_name}</td>
                </tr>
                <tr>
                  <th scope="row">SITE NAME</th>
                  <td>{site_name}</td>
                </tr>
                <tr>
                  <th scope="row">SITE STATUS</th>
                  {site_status === "Site Offline" ? (
                    <td className="bg-danger text-white text-center font-weight-bolder">
                      {site_status}
                    </td>
                  ) : site_status === "Circuit Issues" ? (
                    <td className="bg-warning text-center font-weight-bolder">
                      {site_status}
                    </td>
                  ) : (
                    <td className="bg-success text-center font-weight-bolder">
                      {site_status}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-6">
            <table className="table table-striped">
              <thead className="thead">
                <tr>
                  <th scope="col" className="bg-dark text-white">
                    WAN INFORMATION
                  </th>
                  <th scope="col" className="bg-dark text-white"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="bg-secondary text-white">WAN - 1</th>
                  <td className="bg-secondary text-white"></td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 INDEX</th>
                  <td>{wan1_wan_index}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 CIRCUIT ID</th>
                  <td>{wan1_circuit_id}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 CIRCUIT NAME</th>
                  <td>{wan1_circuit_name}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 CIRCUIT TYPE</th>
                  <td>{wan1_circuit_type}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 CIRCUIT SEVERITY</th>
                  <td>{wan1_circuit_severity}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 1 CIRCUIT STATUS</th>
                  <td>{wan1_status}</td>
                </tr>
                <tr>
                  <th className="bg-secondary text-white">WAN - 2</th>
                  <td className="bg-secondary text-white"></td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 INDEX</th>
                  <td>{wan2_wan_index}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 CIRCUIT ID</th>
                  <td>{wan2_circuit_id}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 CIRCUIT NAME</th>
                  <td>{wan2_circuit_name}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 CIRCUIT TYPE</th>
                  <td>{wan2_circuit_type}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 CIRCUIT SEVERITY</th>
                  <td>{wan2_circuit_severity}</td>
                </tr>
                <tr>
                  <th scope="row">WAN 2 CIRCUIT STATUS</th>
                  <td>{wan2_status}</td>
                </tr>

                {!wan3_wan_index ? (
                  <tr></tr>
                ) : (
                  <Fragment>
                    <tr>
                      <th className="bg-secondary text-white">WAN - 3</th>
                      <td className="bg-secondary text-white"></td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 INDEX</th>
                      <td>{wan3_wan_index}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 CIRCUIT ID</th>
                      <td>{wan3_circuit_id}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 CIRCUIT NAME</th>
                      <td>{wan3_circuit_name}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 CIRCUIT TYPE</th>
                      <td>{wan3_circuit_type}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 CIRCUIT SEVERITY</th>
                      <td>{wan3_circuit_severity}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 3 CIRCUIT STATUS</th>
                      <td>{wan3_status}</td>
                    </tr>
                  </Fragment>
                )}
                {!wan4_wan_index ? (
                  <tr></tr>
                ) : (
                  <Fragment>
                    <tr>
                      <th className="bg-secondary text-white">WAN - 4</th>
                      <td className="bg-secondary text-white"></td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 INDEX</th>
                      <td>{wan4_wan_index}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 CIRCUIT ID</th>
                      <td>{wan4_circuit_id}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 CIRCUIT NAME</th>
                      <td>{wan4_circuit_name}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 CIRCUIT TYPE</th>
                      <td>{wan4_circuit_type}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 CIRCUIT SEVERITY</th>
                      <td>{wan4_circuit_severity}</td>
                    </tr>
                    <tr>
                      <th scope="row">WAN 4 CIRCUIT STATUS</th>
                      <td>{wan4_status}</td>
                    </tr>
                  </Fragment>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SdWanProfile;
