import React, { useReducer } from "react";
import TechNotesContext from "./techNotesContext";
import axios from "axios";
import techNotesReducer from "./techNotesReducer";

import {
  GET_TECH_NOTES,
  GET_TECH_NOTE,
  CREATE_TECH_NOTE,
  UPDATE_TECH_NOTE,
  DELETE_TECH_NOTE,
} from "../types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const TechNotesState = (props) => {
  const initialstate = {
    kbs: null,
    kb: null,
  };

  const [state, dispatch] = useReducer(techNotesReducer, initialstate);

  const getKbs = async () => {
    const res = await axios.get("/api/knowledgebases/");
    dispatch({
      type: GET_TECH_NOTES,
      payload: res.data,
    });
  };

  const getKb = async (id) => {
    const res = await axios.get(`/api/knowledgebases/${id}`);
    dispatch({
      type: GET_TECH_NOTE,
      payload: res.data,
    });
  };

  const updateKb = async (data) => {
    try {
      const res = await axios.put(`/api/knowledgebases/${data._id}`, data);
      dispatch({
        type: UPDATE_TECH_NOTE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteKb = async (id) => {
    await axios.delete(`/api/knowledgebases/${id}`);
    dispatch({
      type: DELETE_TECH_NOTE,
      payload: id,
    });
  };

  const createKb = async (data) => {
    const res = await axios.post(`/api/knowledgebases/`, data, config);
    dispatch({
      type: CREATE_TECH_NOTE,
      payload: res.data,
    });
  };

  return (
    <TechNotesContext.Provider
      value={{
        kbs: state.kbs,
        kb: state.kb,
        getKbs,
        getKb,
        updateKb,
        deleteKb,
        createKb,
      }}
    >
      {props.children}
    </TechNotesContext.Provider>
  );
};

export default TechNotesState;
