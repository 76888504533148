import React, { useContext, useEffect, useState } from "react";
import MosContext from "../../../context/logs/logsContext";
import moment from "moment";

const VoiceStatus = (props) => {
  const mosContext = useContext(MosContext);
  const { mos_logs, getMosScoresLastHour } = mosContext;
  const [siteAverages, setSiteAverages] = useState([]);

  useEffect(() => {
    getMosScoresLastHour();

    setInterval(() => {
      getMosScoresLastHour();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mos_logs !== null) {
      // ! GET ONE HOUR PREVIOUS TIME
      const currentTime = moment.utc(new Date()).format();
      const prevOneHour = moment.utc(currentTime).subtract(1, "hour").format();
      //  ! FILTER LOGS THAT ARE FROM WITHING ONE HOUR
      const logsWithinLastHours = mos_logs.filter(
        (log) => log.TimeStamp > prevOneHour
      );
      // ! FILTER OUT UNIQUE SITE NAMES
      const uniqueSiteNames = logsWithinLastHours.reduce(
        (unique, item) => {
          return unique.includes(item.Site) ? unique : [...unique, item.Site];
        },
        // eslint-disable-next-line
        []
      );
      //   ! COLLECT MOS SCORES FOR EACH SITE & CALCULATE THE AVERAGE MOS SCORE

      const mosAveragesBySite = uniqueSiteNames.map((site) => {
        const siteLogs = logsWithinLastHours.filter((log) => site === log.Site);

        return siteLogs;
      });
      var i;
      let avgMosBySite = [];
      for (i = 0; i < mosAveragesBySite.length; i++) {
        let count = 0;
        let tally = 0;
        let length = mosAveragesBySite[i].length;
        mosAveragesBySite[i].map((site) => {
          tally = parseFloat(site.MOS) + tally;
          count = count + 1;
          if (count === length) {
            const siteAvg = tally / length;

            const averageBySite = {
              _id: site._id,
              Site: site.Site,
              Total: tally,
              length: length,
              AvgMOS: siteAvg.toFixed(2),
            };
            avgMosBySite.push(averageBySite);
            count = 0;
            tally = 0;
          }
          return null;
        });
      }
      const sortedAvgMosBySite = avgMosBySite.sort(function (a, b) {
        return parseFloat(a.AvgMOS) - parseFloat(b.AvgMOS);
      });
      setSiteAverages(sortedAvgMosBySite);
    }
    // eslint-disable-next-line
  }, [mos_logs]);

  return (
    <div className="container border border-dark">
      <div className="row bg-warning mt-2">
        <h1 className="mx-auto dashboard-heading text-dark">VOICE</h1>
      </div>
      <div className="row">
        <table className="table">
          <tbody>
            {siteAverages !== null ? (
              siteAverages.map((site) =>
                site.AvgMOS < 4.0 ? (
                  <tr
                    key={site._id}
                    className="bg-warning text-white dashboard-data"
                  >
                    <td className="text-center dashboard-item">{site.Site}</td>
                    <td className="text-center dashboard-item">
                      {site.AvgMOS}
                    </td>
                  </tr>
                ) : null
              )
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VoiceStatus;
