import React from 'react'

const Footer = () => {
    return (
        <div  style={{height: '100px'}} className="container-fluid bg-dark">
        </div>
    )
}

export default Footer
