import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
  } from '../types';
  
  export default (state, action) => {
    switch (action.type) {
      case REGISTER_SUCCESS:
        return {
          ...state,
          ...action.payload,
          registration_complete: true,
          password_validated: true,
          loading: false
        };
      case LOGIN_SUCCESS:
        localStorage.setItem('auth_token', action.payload.token);
        return {
          ...state,
          ...action.payload,
          isAuthenticated: true,
          loading: false
        };
      case REGISTER_FAIL:
        return {
          ...state,
          error: action.payload
        };
      case AUTH_ERROR:
      case LOGIN_FAIL:
      case LOGOUT:
        localStorage.removeItem('auth_token');
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null,
          error: action.payload
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null
        };
      case USER_LOADED:
        return {
          ...state,
          isAuthenticated: true,
          loading: false,
          user: action.payload
        };
  

      default:
        return state;
    }
  };
  