import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  CLEAR_CURRENT,
  GET_USER,
  GET_USERS,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        users: action.payload,
        loading: false,
        submitComplete: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case EDIT_USER:
      return {
        ...state,
        user: action.payload,
        users: action.payload,
        ...state,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};
