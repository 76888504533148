export const GET_EC_STATUS = "GET_EC_STATUS";
export const GET_SD_STATUS = "GET_SD_STATUS";
export const GET_IP_STATUS = "GET_IP_STATUS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOAD_DASHBOARD = "LOAD_DASHBOARD";
export const LOAD_WIRELESS = "LOAD_WIRELESS";
export const LOAD_IP = "LOAD_IP";
export const LOAD_SDWAN = "LOAD_SDWAN";
export const LOAD_SDWAN_PROFILE = "LOAD_SDWAN_PROFILE";
export const LOAD_USER_PAGE = "LOAD_USER_PAGE";
export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const NEW_USER_PAGE = "NEW_USER_PAGE";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const LOAD_CURRENT_SDWAN = "LOAD_CURRENT_SDWAN";
export const CLEAR_CURRENT_SDWAN = "CLEAR_CURRENT_SDWAN";
export const GET_EC_USAGE = "GET_EC_USAGE";
export const LOAD_EC_USAGE = "LOAD_EC_USAGE";
export const CLEAR_EC_USAGE = "CLEAR_EC_USAGE";
export const GET_ALERTS = "GET_ALERTS";
export const DELETE_ALERT = "DELETE_ALERT";
export const EDIT_ALERT = "EDIT_ALERT";
export const GET_ALERT = "GET_ALERT";
export const LOAD_ALERTS_PAGE = "LOAD_ALERTS_PAGE";
export const LOAD_ALERT_DETAIL = "LOAD_ALERT_DETAIL";
export const GET_MOS_LOGS = "GET_MOS_LOGS";
export const DELETE_MOS_LOG = "DELETE_MOS_LOG";
export const GET_MOS_LOG = "GET_MOS_LOG";
export const LOAD_MOS_SCORE_PAGE = "LOAD_MOS_SCORE_PAGE";
export const LOAD_MOS_SCORE_DETAIL = "LOAD_MOS_SCORE_DETAIL";
export const LOAD_A_PAGE = "LOAD_A_PAGE";
export const GET_UNREACHABLES = "GET_UNREACHABLES";
export const GET_SWITCHOVERS = "GET_SWITCHOVERS";
export const GET_TECH_NOTES = "GET_TECH_NOTES";
export const GET_TECH_NOTE = "GET_TECH_NOTE";
export const CREATE_TECH_NOTE = "CREATE_TECH_NOTE";
export const UPDATE_TECH_NOTE = "UPDATE_TECH_NOTE";
export const DELETE_TECH_NOTE = "DELETE_TECH_NOTE";
