import React, { Fragment, useContext, useEffect } from "react";
import NavContext from "../../context/navigation/navContext";
import UsersContext from "../../context/users/userContext";
import SdContext from "../../context/sdWan/sdContext";
import AlertsContext from "../../context/alerts/alertsContext";
import LogsContext from "../../context/logs/logsContext";
import Wireless from "../modules/enterpriseConnect/Wireless";
import MosScores from "../modules/mosScores/MosScores";
import SdWan from "../modules/sdWan/SdWan";
import Ip from "../modules/internet/Ip";
import UserPage from "../modules/users/UserPage";
import TechNotes from "../modules/techNotes/TechNotes";
import NewUser from "../auth/NewUser";
import UserProfile from "../modules/users/UserProfile";
import SdWanProfile from "../modules/sdWan/SdWanProfile";
import AlertDetail from "../modules/alerts/AlertDetail";
import MosScoreDetail from "../modules/mosScores/MosScoreDetail";
import VoiceStatusList from "../modules/voice/VoiceStatusList";
import SwitchoversList from "../modules/voice/SwitchoversList";
import UnreachableList from "../modules/voice/UnreachableList";
import EcUsage from "../modules/enterpriseConnect/EcUsage";
import Dashboard from "./Dashboard";
import Alerts from "./Alerts";

const Home = () => {
  const navContext = useContext(NavContext);
  const sdContext = useContext(SdContext);
  const usersContext = useContext(UsersContext);
  const alertsContext = useContext(AlertsContext);
  const logsContext = useContext(LogsContext);
  const { getAlerts, alerts, alert, getAlert, editAlert } = alertsContext;
  const {
    step,
    newUser,
    showUsers,
    loadUserProfile,
    loadSdWanProfile,
    loadSdWan,
    loadEcUsage,
    loadAlertsPage,
    loadAlertDetail,
    loadMosScorePage,
    loadMosScoreDetail,
  } = navContext;
  const { user } = usersContext;
  const { currentSdwan, loadCurrentSdwan, clearCurrentSdwan } = sdContext;
  const {
    mos_logs,
    mos_log,
    getMosScoresLastHour,
    getMosScore,
    unreachables,
    getUnreachables,
    switchovers,
    getSwitchovers,
  } = logsContext;

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line
  }, [step]);

  switch (step) {
    case "mos":
      return (
        <Fragment>
          <MosScores
            mos_logs={mos_logs}
            getMosScoresLastHour={getMosScoresLastHour}
            getMosScore={getMosScore}
            loadMosScoreDetail={loadMosScoreDetail}
          />
        </Fragment>
      );
    case "tech notes":
      return <TechNotes />;
    case "mos detail":
      return (
        <MosScoreDetail mos_log={mos_log} loadMosScorePage={loadMosScorePage} />
      );
    case "voice status":
      return (
        <VoiceStatusList
          mos_logs={mos_logs}
          getMosScoresLastHour={getMosScoresLastHour}
          getMosScore={getMosScore}
          loadMosScoreDetail={loadMosScoreDetail}
        />
      );
    case "switchovers":
      return (
        <SwitchoversList
          getSwitchovers={getSwitchovers}
          switchovers={switchovers}
        />
      );
    case "unreachable":
      return (
        <UnreachableList
          getUnreachables={getUnreachables}
          unreachables={unreachables}
        />
      );
    case "wireless":
      return <Wireless loadEcUsage={loadEcUsage} />;
    case "alerts":
      return <Alerts loadAlertDetail={loadAlertDetail} getAlert={getAlert} />;
    case "alert detail":
      return (
        <AlertDetail
          alert={alert}
          editAlert={editAlert}
          loadAlertsPage={loadAlertsPage}
          currentSdwan={currentSdwan}
          clearCurrentSdwan={clearCurrentSdwan}
        />
      );
    case "sdwan":
      return (
        <SdWan
          loadSdWanProfile={loadSdWanProfile}
          loadCurrentSdwan={loadCurrentSdwan}
        />
      );
    case "sdwanProfile":
      return (
        <SdWanProfile
          loadSdWan={loadSdWan}
          loadCurrentSdwan={loadCurrentSdwan}
          currentSdwan={currentSdwan}
          clearCurrentSdwan={clearCurrentSdwan}
        />
      );
    case "ip":
      return <Ip />;
    case "ecUsage":
      return <EcUsage />;
    case "Users":
      return <UserPage newUser={newUser} loadUserProfile={loadUserProfile} />;
    case "NewUser":
      return <NewUser showUsers={showUsers} />;
    case "userProfile":
      return <UserProfile showUsers={showUsers} user={user} />;

    case "dashboard":
      return (
        <Dashboard
          alerts={alerts}
          loadSdWanProfile={loadSdWanProfile}
          loadCurrentSdwan={loadCurrentSdwan}
          loadAlertsPage={loadAlertsPage}
        />
      );
    default:
      return (
        <Fragment>
          <div>
            <Dashboard />;
          </div>
        </Fragment>
      );
  }
};

export default Home;
