import { GET_EC_USAGE, CLEAR_EC_USAGE } from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_EC_USAGE:
      return {
        ...state,
        ecUsageDataLoading: false,
        ecUsage: action.payload,
      };
    case CLEAR_EC_USAGE:
      return {
        ...state,
        ecUsageDataLoading: true,
        ecUsage: null,
      };

    default:
      return state;
  }
};
