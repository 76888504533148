import React, { useContext, useEffect, useState } from "react";
import EcContext from "../../../context/enterpriseConnect/ecContext";
import EcFullStatusList from "./EcFullStatusList";

const Wireless = (props) => {
  const ecContext = useContext(EcContext);
  const { ecConnections, ecStatusLoading, getEcStatus } = ecContext;
  const { loadEcUsage } = props;
  const [sitesUp, setSitesUp] = useState(null);
  const [sitesDown, setSitesDown] = useState(null);
  useEffect(() => {
    getEcStatus();

    setInterval(() => {
      getEcStatus();
    }, 60000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ecConnections !== null) {
      var offline = ecConnections.filter((site) => {
        return site.status === "offline";
      });
      var online = ecConnections.filter((site) => {
        return site.status === "online";
      });
      setSitesDown(offline);
      setSitesUp(online);
    }
  }, [ecConnections]);

  return (
    <div className="container">
      <div className="row bg-secondary rounded mt-2">
        <h1 className="mx-auto text-white">ENTERPRISE CONNECT STATUS</h1>
        <button
          className="btn btn-dark text-warning text-bolder m-2"
          onClick={loadEcUsage}
        >
          MTD USAGE
        </button>
      </div>
      <div className="row">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th className="text-center" scope="col">
                Site ID
              </th>
              <th className="text-center" scope="col">
                Company
              </th>
              <th className="text-center" scope="col">
                Site
              </th>
              <th className="text-center" scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {!ecStatusLoading && sitesDown !== null ? (
              sitesDown.map((connection) => (
                <EcFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
            {!ecStatusLoading && sitesUp !== null ? (
              sitesUp.map((connection) => (
                <EcFullStatusList connection={connection} key={connection.id} />
              ))
            ) : (
              <tr>
                <td>LOADING....</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wireless;
