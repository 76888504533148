import React from "react";
import "./css/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import AlertState from "./context/alert/AlertState";
import AlertsState from "./context/alerts/AlertsState";
import TechNotesState from "./context/techNotes/TechNotesState";
import LogsState from "./context/logs/LogsState";
import AuthState from "./context/auth/AuthState";
import EcState from "./context/enterpriseConnect/EcState";
import EcUsageState from "./context/ecUsage/EcUsageState";
import SdState from "./context/sdWan/SdState";
import IpState from "./context/ipMonitoring/IpState";
import NavState from "./context/navigation/NavState";
import StatusBar from "./components/layout/StatusBar";
import UsersState from "./context/users/UsersState";
import Alerts from "./components/layout/Alerts";
import NavBar from "./components/layout/NavBar";
import Footer from "./components/layout/Footer";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/auth/ForgotPassword";
import NewPassword from "./components/auth/NewPassword";
import Home from "./components/pages/Home";

function App() {
  return (
    <AlertState>
      <AlertsState>
        <AuthState>
          <UsersState>
            <EcState>
              <LogsState>
                <EcUsageState>
                  <SdState>
                    <IpState>
                      <TechNotesState>
                        <NavState>
                          <Router>
                            <NavBar />
                            <Alerts />
                            <StatusBar />
                            <Switch>
                              <Route exact path="/" component={Login} />
                              <Route
                                exact
                                path="/forgotPassword"
                                component={ForgotPassword}
                              />
                              <Route
                                exact
                                path="/newPassword"
                                component={NewPassword}
                              />
                              <Route
                                exact
                                path="/reset/:token"
                                component={NewPassword}
                              />
                              <PrivateRoute
                                exact
                                path="/dashboard"
                                component={Home}
                              />
                            </Switch>
                            <Footer />
                          </Router>
                        </NavState>
                      </TechNotesState>
                    </IpState>
                  </SdState>
                </EcUsageState>
              </LogsState>
            </EcState>
          </UsersState>
        </AuthState>
      </AlertsState>
    </AlertState>
  );
}

export default App;
